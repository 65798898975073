import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CartTenantDetails,
    ExpressCheckoutProps,
    PrepCartForCheckoutProps,
    getCartTenantDetails,
    prepCartForCheckout,
} from '@vp/om-express-checkout';
import { bugtracker, useIdentityContext } from '@99designs/design-services-common';
import { useAlertContext } from '@99designs/design-services-layouts';
import {
    ClaimBriefMutation,
    formatProductData,
    getCartProductData,
    getWorkIdFromDynamicFields,
    shouldUpdateDesignLiveAppointment,
    submitRequestAndTrack,
    transformProductForCart,
    updateDesignLiveAppointment,
    useClaimBriefMutation,
    useDesignLiveAppointmentUpdatedMutation,
    useSubmitRequestMutation,
} from '@99designs/feature-design-services-brief';
import { __, getCountryCode, getCurrentCulture } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';

export function getTenantForCart(locale: string) {
    const cartEnv = getEnvironment() === 'production' ? 'PROD' : 'DEV';
    const countryCode = getCountryCode(locale);
    return `VP-${countryCode}-${cartEnv}`;
}

async function cacheFunction<T>(callback: () => Promise<T>, key: string): Promise<[T, boolean]> {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
        try {
            return [JSON.parse(cachedData), true];
        } catch (e) {
            bugtracker.notify(e, (event) => {
                event.addMetadata('debug info', 'cache_key', key);
            });
        }
    }
    const data = await callback();
    if (typeof data === 'object' && data !== null) {
        localStorage.setItem(key, JSON.stringify(data));
    }
    localStorage.setItem(key, JSON.stringify(data));
    return [data, false];
}

async function getExpressCartId(
    data: ClaimBriefMutation,
    tenantName: string,
    locale: string,
    accessToken: string,
    shopperId: string
) {
    const briefId = data.claimStructuredBrief.id;
    const correlationId = data.claimStructuredBrief.correlationId;
    const workId = getWorkIdFromDynamicFields(data.claimStructuredBrief.dynamicFields);
    const productData = formatProductData(data);
    const cartProduct = getCartProductData(productData, briefId, correlationId, data);
    const cartData = transformProductForCart(cartProduct, true, workId);
    const args: Omit<PrepCartForCheckoutProps, 'authHeader'> = {
        shopperId,
        tenantName,
        locale,
        cartDetails: { type: 'alt' },
        prepCartForCheckoutRequest: {
            items: [
                {
                    ...cartData,
                    product: {
                        ...cartData.product,
                        selectedAttributes: cartData.product.selectedAttributes ?? [],
                    },
                    correlationId,
                },
            ],
            pricingContextTypeOverride: 'SHOPPER_DEFAULT',
        },
    };
    const hashArgs = JSON.stringify(args);
    return cacheFunction(async () => {
        const cartId = await prepCartForCheckout({
            authHeader: `Bearer ${accessToken}`,
            ...args,
        });
        return cartId;
    }, hashArgs);
}

function useSetShowExpressCheckout() {
    const nav = useNavigate();

    const setShowExpressCheckout = useCallback(
        (isOpen: boolean) => {
            if (isOpen) return;
            const currentPath = window.location.pathname;
            const newPath = currentPath.replace(/\/express-checkout$/, '');
            nav(newPath);
        },
        [nav]
    );

    return setShowExpressCheckout;
}

export function getEnvironment() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost') || hostname.includes('staging')) {
        return 'staging';
    }
    return 'production';
}

async function fetchCartTenantDetails(locale: string) {
    const environment = getEnvironment();
    return cacheFunction(async () => {
        const details = await getCartTenantDetails('vistaprint', locale, environment);
        return details;
    }, `cartTenantDetails_${locale}_${environment}`);
}

export function useExpressCart(
    briefId: string
):
    | { type: 'loading' }
    | { type: 'completed'; data: ExpressCheckoutProps }
    | { type: 'error'; error: Error } {
    const { auth, shopperId, accessToken } = useIdentityContext();
    const page = usePage();
    const { showError } = useAlertContext();
    const locale = getCurrentCulture();
    const tenantName = getTenantForCart(locale);
    const setShowExpressCheckout = useSetShowExpressCheckout();
    const [altCartId, setAltCartId] = useState<string | null>(null);
    const [cartTenant, setCartTenant] = useState<CartTenantDetails | null>(null);
    const [designLiveAppointmentUpdated] = useDesignLiveAppointmentUpdatedMutation();
    const [mutation, claimResult] = useClaimBriefMutation({
        variables: {
            id: briefId,
        },
    });
    const [submitRequest, submitResult] = useSubmitRequestMutation();

    useEffect(() => {
        const run = async () => {
            try {
                const { data: originalData } = await mutation({
                    variables: {
                        id: briefId,
                    },
                });
                let data = originalData;
                if (!data) {
                    throw new Error('An error occurred while claiming the brief');
                }

                const [tenantDetails] = await fetchCartTenantDetails(locale);
                setCartTenant(tenantDetails);
                const productData = formatProductData(data);
                const request = data.claimStructuredBrief.request;
                if (request == null) {
                    throw new Error('Request is missing');
                }
                if (shouldUpdateDesignLiveAppointment(productData, request)) {
                    await updateDesignLiveAppointment(
                        productData,
                        designLiveAppointmentUpdated,
                        request
                    );
                    // reload the brief to reflect the updated design live appointment
                    const result = await mutation({
                        variables: {
                            id: briefId,
                        },
                    });
                    data = result.data;
                    if (!data) {
                        throw new Error('An error occurred while claiming the brief');
                    }
                }
                const [cartId, hasRequestForBriefInCart] = await getExpressCartId(
                    data,
                    tenantName,
                    locale,
                    accessToken,
                    shopperId
                );
                setAltCartId(cartId);
                if (!hasRequestForBriefInCart) {
                    await submitRequestAndTrack(submitRequest, request, productData);
                }
            } catch (e) {
                showError(
                    __(
                        'Sorry, we encountered an error while preparing your cart for checkout. Please try again'
                    )
                );
                bugtracker.notify(e, (event) => {
                    event.addMetadata('debug info', 'briefId', briefId);
                });
                setShowExpressCheckout(false);
            }
        };
        run();
    }, [
        briefId,
        locale,
        tenantName,
        mutation,
        submitRequest,
        accessToken,
        shopperId,
        designLiveAppointmentUpdated,
        showError,
        setShowExpressCheckout,
    ]);

    if (claimResult.error) {
        return { type: 'error', error: claimResult.error };
    }
    if (submitResult.error) {
        return { type: 'error', error: submitResult.error };
    }
    if (altCartId === null || cartTenant === null || claimResult.loading || submitResult.loading) {
        return { type: 'loading' };
    }

    return {
        type: 'completed',
        data: {
            cartDetails: {
                type: 'alt',
                altCartId,
            },
            shopperId,
            locale,
            auth,
            tenantDetails: cartTenant,
            pageDetails: page.pageData,
            showExpressCheckout: true,
            setShowExpressCheckout,
            debug: false,
        },
    };
}
