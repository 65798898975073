import { createContext, useContext } from 'react';

export interface ViewContextProps {
    readonly isReviewMode?: boolean;
    readonly isMultiStep?: boolean;
    readonly isEditMode?: boolean;
    readonly isCollaborationMode?: boolean;
}

export const ViewContext = createContext<ViewContextProps>({
    isReviewMode: false,
    isMultiStep: false,
    isEditMode: false,
});

export function ViewContextProvider({
    children,
    value,
}: {
    children: React.ReactNode;
    value: ViewContextProps;
}) {
    const urlParams = new URLSearchParams(window.location.search);
    const isEditMode = urlParams.has('isEditMode');
    return <ViewContext.Provider value={{ isEditMode, ...value }}>{children}</ViewContext.Provider>;
}

export const useViewContext = () => {
    return useContext(ViewContext);
};

export const useIsReviewMode = () => {
    return useViewContext().isReviewMode;
};

export const useIsMultiStep = () => {
    return useViewContext().isMultiStep;
};

export const useIsEditMode = (): boolean => {
    return useViewContext().isEditMode ?? false;
};
