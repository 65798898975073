import Bugsnag from '@bugsnag/js';
import { getVariation, waitTillAvailable } from '@vp/ab-reader';

export async function getExperimentVariation(expertimentKey: string): Promise<string | null> {
    const available = await waitTillAvailable(3000);
    if (!available) {
        Bugsnag.notify(new Error('AB reader is not available'));
        return null;
    }
    return getVariation(expertimentKey);
}
