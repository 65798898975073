import { Box, Spinner } from '@vp/swan';
import styled from 'styled-components';

const FieldWrapper = styled(Box)<{ opacity?: number }>`
    opacity: ${({ opacity }) => opacity};
    width: 100%;
`;

type LoadingOverlayProps = {
    loading: boolean;
    children: React.ReactNode;
};

export function LoaderOverlay({ loading, children }: LoadingOverlayProps) {
    return (
        <>
            <FieldWrapper
                opacity={loading ? 0.5 : 1}
                style={loading ? { pointerEvents: 'none' } : {}}
            >
                {children}
            </FieldWrapper>
            {loading && (
                <Box
                    display="flex"
                    width="100%"
                    height="100%"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                    }}
                >
                    <Spinner accessibleText="loading" size={'super'} />
                </Box>
            )}
        </>
    );
}
