import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StandardForm } from '@vp/swan';
import { getSdpUrl } from '@99designs/i18n';
import { MultiStep } from '@99designs/swan-wizard';
import { usePage } from '@99designs/tracking';
import { useBriefContext } from '../BriefContext';
import { BriefFormProvider } from '../BriefContext/BriefFormContext';
import { isBifrostBrief } from '../BriefContext/isBifrostBrief';
import { BriefFormProps, FormInput } from './BriefForm';
import { FieldGroup, fieldGroups } from './FieldGroup';
import { useFormGroups } from './FieldGroup/useFormGroups';
import { FieldProcessingProvider } from './FieldProcessingProvider';
import { Review } from './Review';
import { ViewContextProvider, useIsEditMode } from './ViewContext';
import { getSubmit } from './getSubmit';
import { useRegisterWithTracking } from './useRegisterWithTracking';

function useIsStepValid(
    groups: ReturnType<typeof fieldGroups>,
    trigger: (name?: string | string[]) => Promise<boolean>
) {
    return useCallback(
        async (currentStep: number) => {
            // We subtract 1 from the current step because the intro step is not a form group

            const group = groups[currentStep - 1];
            if (!group) {
                return true;
            }

            return await trigger(group.fields.map((f) => f.id));
        },
        [groups, trigger]
    );
}

export const ReviewForm: React.FC<Pick<BriefFormProps, 'brief' | 'onSubmit'>> = ({
    brief,
    onSubmit,
}) => {
    const { intro, groups, workEntityField } = useFormGroups(brief);
    const { pageData } = usePage();
    const { product } = useBriefContext();

    const methods = useForm<FormInput>({
        shouldUnregister: false,
    });
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        setError,
        formState: { errors: formErrors, dirtyFields },
        getValues,
        trigger,
    } = methods;

    // we want the validation to fire on load as all fields should be filled out
    useEffect(() => {
        void trigger();
    }, [trigger]);

    const registerWithTracking = useRegisterWithTracking(
        brief,
        register,
        getValues,
        dirtyFields,
        pageData
    );

    const isEditMode = useIsEditMode();

    const [groupFormKey, setGroupFormKey] = useState(0);

    const steps = [
        <ViewContextProvider value={{ isReviewMode: true, isMultiStep: true }}>
            <Review
                groups={[intro, ...groups]}
                briefId={brief.id}
                groupFormSections={[intro, ...groups].reduce((acc, g) => {
                    acc[g.id] = (
                        <FieldGroup
                            key={`${g.id}-${groupFormKey}`}
                            group={g}
                            register={registerWithTracking}
                            errors={formErrors}
                            setValue={setValue}
                            watch={watch}
                            clearErrors={clearErrors}
                            setError={setError}
                        />
                    );
                    return acc;
                }, {} as Record<string, React.ReactNode>)}
                product={product || null}
                workEntity={workEntityField}
                groupFormKey={groupFormKey}
                setGroupFormKey={setGroupFormKey}
            />
        </ViewContextProvider>,
    ];

    const validate = useIsStepValid(groups, trigger);

    if (
        brief.groups === undefined ||
        product == null ||
        (!product?.key && !isBifrostBrief(brief))
    ) {
        return null;
    }

    return (
        <StandardForm
            style={{ height: '100%' }}
            onSubmit={getSubmit(handleSubmit, onSubmit, brief.id)}
        >
            <FieldProcessingProvider>
                <BriefFormProvider briefId={brief.id}>
                    <FormProvider {...methods}>
                        <MultiStep
                            steps={steps}
                            validate={validate}
                            title={product.title}
                            hideExitPopUp={isEditMode}
                            exitUrl={
                                product.fulfilmentMpvId
                                    ? getSdpUrl(product.fulfilmentMpvId)
                                    : '/design'
                            }
                        />
                    </FormProvider>
                </BriefFormProvider>
            </FieldProcessingProvider>
        </StandardForm>
    );
};
