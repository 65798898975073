/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type CanUseExpressCheckoutQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CanUseExpressCheckoutQuery = { __typename: 'Query'; canUseExpressCheckout: boolean };

export const CanUseExpressCheckoutDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'canUseExpressCheckout' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'canUseExpressCheckout' } },
                ],
            },
        },
    ],
};

/**
 * __useCanUseExpressCheckoutQuery__
 *
 * To run a query within a React component, call `useCanUseExpressCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanUseExpressCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanUseExpressCheckoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanUseExpressCheckoutQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CanUseExpressCheckoutQuery,
        CanUseExpressCheckoutQueryVariables
    >
) {
    return Apollo.useQuery<CanUseExpressCheckoutQuery, CanUseExpressCheckoutQueryVariables>(
        CanUseExpressCheckoutDocument,
        baseOptions
    );
}
export function useCanUseExpressCheckoutLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CanUseExpressCheckoutQuery,
        CanUseExpressCheckoutQueryVariables
    >
) {
    return Apollo.useLazyQuery<CanUseExpressCheckoutQuery, CanUseExpressCheckoutQueryVariables>(
        CanUseExpressCheckoutDocument,
        baseOptions
    );
}
export type CanUseExpressCheckoutQueryHookResult = ReturnType<typeof useCanUseExpressCheckoutQuery>;
export type CanUseExpressCheckoutLazyQueryHookResult = ReturnType<
    typeof useCanUseExpressCheckoutLazyQuery
>;
export type CanUseExpressCheckoutQueryResult = Apollo.QueryResult<
    CanUseExpressCheckoutQuery,
    CanUseExpressCheckoutQueryVariables
>;
