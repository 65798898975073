import React, { MouseEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@vp/swan';
import { useIdentityContext } from '@99designs/design-services-common';
import { Workflow } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { BRIEF_PAGE_NAME, sendExperimentClickTrackingEvent } from '@99designs/tracking';
import { useCart } from '../../lib/hooks';
import { getExperimentVariation } from '../Submit/getExperiment';
import { useFieldProcessing } from './FieldProcessingProvider';

export const addToCartEventTracking = {
    label: 'Design Services Added',
    eventDetail: '/design/briefs/create;/c;BriefForm;AddToCart-CTA',
    navigationDetail: 'AddToCart-CTA',
    category: 'Product Added',
};

function useSubmitBriefCTACopy(locale: string, briefId: string, workflow?: Workflow) {
    const { accessToken, shopperId, isSignedIn } = useIdentityContext();
    const { isInCart } = useCart(locale, { accessToken, shopperId });
    const [CTAcopy, setCTAcopy] = useState(
        workflow === 'CARE_EXPRESS' ? __('Submit') : __('Add to cart')
    );

    useEffect(() => {
        async function getCartData() {
            const briefInCart = await isInCart(briefId);
            setCTAcopy(briefInCart ? __('Submit Changes') : __('Add to cart'));
        }
        if (isSignedIn && workflow !== 'CARE_EXPRESS') {
            getCartData();
        }
    }, [briefId, isInCart, isSignedIn, workflow]);

    return CTAcopy;
}

export const SubmitBriefButton = ({
    briefId,
    locale,
    loading,
    workflow,
}: {
    briefId: string;
    locale: string;
    loading: boolean;
    workflow: Workflow | undefined;
}) => {
    const CTAcopy = useSubmitBriefCTACopy(locale, briefId, workflow);
    const { processingFields } = useFieldProcessing();
    const isProcessing = processingFields.length > 0;
    return (
        <Button
            data-section={addToCartEventTracking.label}
            data-position={0}
            data-translation={addToCartEventTracking.navigationDetail}
            skin="primary"
            type="submit"
            disabled={loading || isProcessing}
        >
            {CTAcopy}
        </Button>
    );
};

export const SubmitMultiStepBriefButton = ({
    isEditMode,
    skin = 'primary',
    width = 'standard',
}: {
    isEditMode: boolean;
    width?: 'wide' | 'standard' | 'full-width';
    skin?: 'primary' | 'secondary';
}) => {
    const CTAcopy = isEditMode ? __('Submit Changes') : __('Add to cart');
    const {
        formState: { errors },
        getValues,
    } = useFormContext();
    const { processingFields } = useFieldProcessing();
    const isProcessing = processingFields.length > 0;
    const hasErrors = Object.keys(errors).length > 0;

    const fireExperiment = async (_: MouseEvent<HTMLButtonElement>) => {
        const experimentName = 'paid_design_services_in_studio_help_panel_2';
        const values = getValues();
        const requestType = values.requestType;
        const isDesignLive = requestType === 'DesignLive';
        const isStandard = requestType === 'Standard';

        let experimentDetail = '';
        if (isDesignLive) {
            experimentDetail = 'expo_2347_Design_Expert_Brief_Submitted_Design_Live';
        } else if (isStandard) {
            experimentDetail = 'expo_2347_Design_Expert_Brief_Submitted_Email_Review';
        }

        const variationName = await getExperimentVariation(experimentName);
        if (variationName === null) return true;

        sendExperimentClickTrackingEvent({
            pageName: BRIEF_PAGE_NAME,
            pageSection: 'AddToCart-CTA',
            pageStage: '',
            experimentDetail,
            experimentName,
            label: 'Design Services Added',
            variationName,
        });

        return true;
    };

    return (
        <Button
            onClick={fireExperiment}
            data-section={addToCartEventTracking.label}
            data-position={0}
            data-translation={addToCartEventTracking.navigationDetail}
            skin={skin}
            width={width}
            type="submit"
            disabled={isProcessing || hasErrors}
        >
            {CTAcopy}
        </Button>
    );
};
