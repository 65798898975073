import { useCallback } from 'react';
import startCase from 'lodash.startcase';
import {
    PAGE_TRACKING_CONFIG,
    sendDesignBriefEditedTrackingEvent,
    usePage,
} from '@99designs/tracking';
import { useBriefContext } from '../BriefContext';

export function useTrackFieldChange(fieldName: string) {
    const { pageData } = usePage();

    const { product } = useBriefContext();
    const label = `Design Brief:${startCase(fieldName)}`;

    return useCallback(() => {
        sendDesignBriefEditedTrackingEvent({
            label,
            mpvId: product?.fulfilmentMpvId ?? '',
            ...pageData,
            ...PAGE_TRACKING_CONFIG['design'],
        });
    }, [product, label, pageData]);
}
