import {
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { bugtracker } from '@99designs/design-services-common';
import { useBriefExperiments } from '../../../../../context';
import { useBriefContext } from '../../../../BriefContext';
import { usePrintProductConfiguratorLazyQuery } from './product-options.generated';
import { AvailableOptionsType } from './types';

type PrintProductOptionContextType = {
    availableOptions: AvailableOptionsType[];
    setAvailableOptions: (options: AvailableOptionsType[]) => void;
    configuratorLoading: boolean;
    configuratorError: Error | undefined;
    compatibilityMap: Record<string, string[]>;
    setSelectedOptions: Dispatch<SetStateAction<Record<string, string>>>;
    selectedOptions: Record<string, string>;
    isProductOptionsFeatureFlagEnabled: boolean | null;
    validate: () => Promise<boolean>;
    isValid: (optionKey: string) => boolean;
    touched: Record<string, boolean>;
    setProductOptionTouched: (optionKey?: string) => void;
    setAllProductOptionsTouched: () => void;
};

const ProductOptionsContext = createContext<PrintProductOptionContextType | undefined>(undefined);

export function useProductOptions() {
    const context = useContext(ProductOptionsContext);
    if (!context) {
        throw new Error('useProductOptions must be used within a ProductOptionsProvider');
    }
    return context;
}

export function ProductOptionsProvider({ children }: { children: ReactNode }) {
    const { product, selectedProductOptions } = useBriefContext();
    const { productOptionsEnabled: isProductOptionsFeatureFlagEnabled } = useBriefExperiments();
    const [compatibilityMap, setCompatibilityMap] = useState<Record<string, string[]>>({});
    const [availableOptions, setAvailableOptions] = useState<AvailableOptionsType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
    const [
        getPrintProductConfiguration,
        { loading: configuratorLoading, error: configuratorError },
    ] = usePrintProductConfiguratorLazyQuery();
    const [touched, setTouched] = useState<Record<string, boolean>>({});

    useEffect(() => {
        setSelectedOptions(selectedProductOptions);
    }, [selectedProductOptions]);

    useEffect(() => {
        const fetchFlag = async () => {
            if (product && product.key && product.fulfilmentMpvId) {
                await getPrintProductConfiguration({
                    variables: {
                        input: {
                            mpvId: product.fulfilmentMpvId || '',
                            tenant: 'vistaprint',
                            selectedAttributes: Object.entries(selectedOptions).map(
                                ([key, value]) => ({
                                    attributeKey: key,
                                    valueKey: value,
                                })
                            ),
                            isProductOptionsFeatureFlagEnabled,
                        },
                    },
                    fetchPolicy: 'cache-and-network',
                    onCompleted: (data) => {
                        const productOptionsWithCustomOptions: AvailableOptionsType[] = [];

                        // Filter out "Custom" as a selectable value.

                        data.printProductConfigurator.productOptions.forEach((option) => {
                            const filteredOptionValues = option.values.filter(
                                (value) => value.key !== 'Custom'
                            );
                            productOptionsWithCustomOptions.push({
                                ...option,
                                values: filteredOptionValues,
                            });
                        });

                        setAvailableOptions(productOptionsWithCustomOptions);
                        setCompatibilityMap(
                            Object.fromEntries(
                                data.printProductConfigurator.compatibleOptions.map(
                                    ({ key, value }) => [key, value]
                                )
                            )
                        );
                    },
                    onError: (e) => {
                        bugtracker.notify(`Failed to get product configurator: ${e.message}`);
                    },
                });
            }
        };

        void fetchFlag();
    }, [
        getPrintProductConfiguration,
        product,
        selectedOptions,
        isProductOptionsFeatureFlagEnabled,
    ]);

    // Remove validation for product options. We will keep this logic in case we need to re-enable it in the future
    const isValid = useCallback(
        (optionKey: string) => {
            // const selectedValue = selectedOptions[optionKey];
            // if (!selectedValue) {
            //     return false;
            // }
            // const compatibleOptions = compatibilityMap[optionKey];
            // if (compatibleOptions && !compatibleOptions.includes(selectedValue)) {
            //     return false;
            // }
            return true;
        },
        [selectedOptions, compatibilityMap]
    );

    const validate = useCallback(async () => {
        if (product && product.key && product.fulfilmentMpvId) {
            const results = availableOptions.map((option) => {
                return option.key.includes('Custom') ? true : isValid(option.key);
            });
            return results.every(Boolean);
        }
        return true;
    }, [product, availableOptions, isValid]);

    const setProductOptionTouched = (optionKey?: string) => {
        if (!optionKey) {
            setTouched({});
            return;
        }
        setTouched((prev) => ({ ...prev, [optionKey]: true }));
    };

    const setAllProductOptionsTouched = () => {
        setTouched(Object.fromEntries(availableOptions.map((option) => [option.key, true])));
    };

    return (
        <ProductOptionsContext.Provider
            value={{
                availableOptions,
                setAvailableOptions,
                configuratorLoading,
                configuratorError,
                compatibilityMap,
                setSelectedOptions,
                selectedOptions,
                isProductOptionsFeatureFlagEnabled,
                validate,
                isValid,
                touched,
                setProductOptionTouched,
                setAllProductOptionsTouched,
            }}
        >
            {children}
        </ProductOptionsContext.Provider>
    );
}

export const ProductOptionsMockProvider: FC<{
    values?: Partial<PrintProductOptionContextType>;
    children?: ReactNode | undefined;
}> = ({ children, values }) => {
    return (
        <ProductOptionsContext.Provider
            value={{
                availableOptions: [],
                setAvailableOptions: () => null,
                configuratorLoading: false,
                configuratorError: undefined,
                compatibilityMap: {},
                setSelectedOptions: () => null,
                selectedOptions: {},
                isProductOptionsFeatureFlagEnabled: false,
                validate: () => Promise.resolve(true),
                isValid: () => true,
                touched: {},
                setProductOptionTouched: () => null,
                setAllProductOptionsTouched: () => null,
                ...values,
            }}
        >
            {children}
        </ProductOptionsContext.Provider>
    );
};
