/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { RecentlySelectedProduct } from '../BriefForm/FormFields/ClientConfiguredFields/ProductDropdown/utils';
import {
    BriefProductFragment,
    BriefQuery,
    BriefRequestFragment,
    StructuredBriefFragment,
} from '../BriefForm/brief.generated';
import { isBifrostBrief } from './isBifrostBrief';

const MAX_RECENT_PRODUCTS = 16;

export type NormalisedBriefProduct = Omit<
    BriefProductFragment,
    'fulfilmentStrategy' | 'name' | '__typename' | 'pricing' | 'key'
> & {
    pricing: Omit<BriefProductFragment['pricing'], 'earningsInUsd'>;
    key: string | null;
};

interface BriefContextProps {
    product?: NormalisedBriefProduct;
    updateProduct: (product?: NormalisedBriefProduct) => void;
    onCompleted: (data: BriefQuery) => void;
    recentProducts?: RecentlySelectedProduct[];
    addRecentProduct: (product: RecentlySelectedProduct) => void;
    isUpdatingProduct: boolean;
    setIsUpdatingProduct: React.Dispatch<React.SetStateAction<boolean>>;
    selectedProductOptions: Record<string, string>;
    config: BriefQuery['brief']['config'] | null;
}

export const BriefContext = createContext<BriefContextProps | undefined>(undefined);

interface BriefProviderProps {
    children: ReactNode;
}

export const BriefProvider: React.FC<BriefProviderProps> = ({ children }) => {
    const [product, setProduct] = useState<NormalisedBriefProduct>();
    const [selectedProductOptions, setSelectedProductOptions] = useState<Record<string, string>>(
        {}
    );
    const [config, setConfig] = useState<BriefQuery['brief']['config'] | null>(null);

    // TODO: Recent products should be stored in the browser's local storage.

    const [recentProducts, setRecentProducts] = useState<RecentlySelectedProduct[]>([]);

    const updateProduct = (newProduct?: NormalisedBriefProduct) => {
        setProduct(newProduct);
    };

    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);

    return (
        <BriefContext.Provider
            value={{
                product,
                updateProduct,
                recentProducts,
                selectedProductOptions,
                config,
                addRecentProduct: (product: RecentlySelectedProduct) => {
                    setRecentProducts((prev) => {
                        if (prev.length >= MAX_RECENT_PRODUCTS) {
                            prev.splice(MAX_RECENT_PRODUCTS - 1, 1);
                        }

                        const index = prev.findIndex((p) => p.mpvId === product.mpvId);

                        if (index !== -1) {
                            prev.splice(index, 1);
                        }

                        return [product, ...prev];
                    });
                },
                onCompleted: (data: BriefQuery) => {
                    // The product should not update if it already exists as it may be newer than the cached
                    // data fetched from this query.

                    if (product || !data) {
                        return;
                    }

                    setConfig(data.brief.config ?? null);

                    const prod = formatProductFromProdOrService(data.brief);
                    if (prod) {
                        updateProduct(prod);
                    }
                    const productOptions = data.brief.dynamicFields.find(
                        (df) => df.id === 'productOptions'
                    );
                    if (!productOptions) {
                        return;
                    }
                    if (productOptions.__typename !== 'ClientConfiguredField') {
                        Bugsnag.notify('Expected productOption to be a ClientConfiguredField');
                        return;
                    }
                    if (!productOptions.clientConfiguredValue) {
                        return;
                    }
                    try {
                        const parsedValue = JSON.parse(productOptions.clientConfiguredValue);
                        setSelectedProductOptions(parsedValue);
                    } catch (e) {
                        Bugsnag.notify('Failed to parse product options');
                    }
                },
                isUpdatingProduct,
                setIsUpdatingProduct,
            }}
        >
            {children}
        </BriefContext.Provider>
    );
};

export function formatProductFromProdOrService(
    brief: {
        request: BriefRequestFragment | null;
        product: BriefProductFragment | null;
    } & StructuredBriefFragment
) {
    if (brief.product && isBifrostBrief(brief)) {
        return {
            title: brief.product.title,
            details: brief.product.details,
            pricing: brief.product.pricing,
            key: null,
            fulfilmentMpvId: null,
        };
    }
    if (brief.product && brief.fulfilment) {
        return {
            title: brief.product.title,
            details: brief.product.details,
            pricing: brief.product.pricing,
            key: brief.fulfilment.productKey,
            fulfilmentMpvId: brief.fulfilment.mpvId,
        };
    }
    const es = brief.request?.expertService;
    return expertServiceToProduct(es);
}

export function MockBriefProvider({
    children,
    value = {},
}: {
    children: ReactNode;
    value?: Partial<BriefContextProps>;
}) {
    return (
        <BriefContext.Provider
            value={{
                product: {
                    title: 'Mock Product',
                    details: [],
                    pricing: {
                        __typename: 'FixedPrice',
                        amount: {
                            __typename: 'Money',
                            amountInCents: 1000,
                            currency: 'USD',
                        },
                    },
                    key: 'mock-key',
                    fulfilmentMpvId: 'mock-fulfilment-mpv-id',
                },
                updateProduct: () => {},
                onCompleted: () => {},
                recentProducts: [],
                addRecentProduct: () => {},
                isUpdatingProduct: false,
                setIsUpdatingProduct: () => {},
                selectedProductOptions: {},
                config: null,
                ...value,
            }}
        >
            {children}
        </BriefContext.Provider>
    );
}

export function expertServiceToProduct(
    es?: BriefRequestFragment['expertService']
): Omit<BriefProductFragment, '__typename' | 'fulfilmentStrategy' | 'name'> | null {
    if (!es || !es.printProduct) return null;

    return {
        title: es.title,
        details:
            es.productDetails?.inclusions.map((i) => ({
                __typename: 'ProductPackageDetails',
                title: i.description,
            })) ?? [],
        pricing: {
            __typename: 'FixedPrice',
            amount: {
                __typename: 'Money',
                amountInCents: Math.round(es.price.listPrice.taxed * 100),
                currency: es.price.currency,
            },
            earningsInUsd: 0,
        },
        key: es.printProduct?.mpvId,
        fulfilmentMpvId: es.printProduct.mpvId,
    };
}

export const useBriefContext = () => {
    const context = useContext(BriefContext);

    if (!context) {
        throw new Error('useBriefContext must be used within a BriefProvider');
    }

    return context;
};
