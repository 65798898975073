import { AvailableOptionsType, ProductOptionReviewType, isPrintProductOptionType } from './types';

/**
 *
 * @param selectedOptions The product options selected by the user
 * @param productOptions The available product options
 * @param requireMatch In review, we should display all selected options even if they don't match the available options
 * @returns
 */
export default function findProductOptions(
    selectedOptions: Record<string, string>,
    productOptions: AvailableOptionsType[],
    requireMatch = true
): ProductOptionReviewType[] {
    return Object.entries(selectedOptions)
        .map(([key, value]) => {
            const matchedOption = productOptions.find((option) => option.key === key);

            if (!matchedOption) {
                if (requireMatch) return null;
                return {
                    key,
                    name: key,
                    value: {
                        name: value,
                        imageUrl: undefined,
                        unit: undefined,
                    },
                };
            }

            let matchedValue;
            if (isPrintProductOptionType(matchedOption)) {
                matchedValue = matchedOption.values.find((val) => val.key === value);
                if (!matchedValue) return null;
                matchedValue = { ...matchedValue, unit: undefined };
            } else {
                matchedValue = {
                    name: value,
                    imageUrl: undefined,
                    unit: matchedOption?.unit,
                };
            }

            return {
                key: matchedOption.key,
                name: matchedOption.name,
                value: {
                    name: matchedValue.name,
                    imageUrl: matchedValue.imageUrl,
                    unit: matchedValue.unit,
                },
            };
        })
        .filter((option): option is ProductOptionReviewType => option !== null);
}
