import type { CustomValue, PrintProductOption } from '@99designs/graph-utils/types';

export type AvailableOptionsType = PrintProductOption | CustomValue;

export type ProductOptionReviewType = {
    key: string;
    name: string;
    value: { name: string; imageUrl: string | undefined; unit: string | undefined };
};

export function isPrintProductOptionType(
    option: AvailableOptionsType
): option is PrintProductOption {
    return (option as PrintProductOption).values !== undefined;
}
