import i18n from 'i18next';
import { getHostKeyBySite } from '../localisation/getHostKeyBySite/getHostKeyBySite';
import dadkURLs from './da-dk.json';
import deatURLs from './de-at.json';
import dechURLs from './de-ch.json';
import dedeURLs from './de-de.json';
import enauURLs from './en-au.json';
import encaURLs from './en-ca.json';
import engbURLs from './en-gb.json';
import enieURLs from './en-ie.json';
import eninURLs from './en-in.json';
import ennzURLs from './en-nz.json';
import ensgURLs from './en-sg.json';
import enusURLs from './en-us.json';
import esesURLs from './es-es.json';
import esusURLs from './es-us.json';
import fifiURLs from './fi-fi.json';
import frbeURLs from './fr-be.json';
import frcaURLs from './fr-ca.json';
import frchURLs from './fr-ch.json';
import frfrURLs from './fr-fr.json';
import itchURLs from './it-ch.json';
import ititURLs from './it-it.json';
import nbnoURLs from './nb-no.json';
import nlbeURLs from './nl-be.json';
import nlnlURLs from './nl-nl.json';
import ptptURLs from './pt-pt.json';
import svseURLs from './sv-se.json';

// transformRelativeURLtoAbsoluteURL() iterates over all translated URLs and ensures it prefixes
// paths with it's correct host. This is to ensure that all URLs are absolute values.
// In some instances where translated URLs are prefixed with a language (e.g. `/fr/`), those languages will
// be stripped when fetched resulting in invalid paths. Ensuring they are all absolute works around this issue.
function transformRelativeURLtoAbsoluteURL(
    hostUrlKey: 'vistaprint.com' | '99designs.com',
    urls: TranslationObject
) {
    const urlMap = new Map<string, string>(Object.entries(urls));
    const hostUrlValue = urlMap.get(getHostKeyBySite(hostUrlKey));
    const ignoredUrlKeys = ['vistaprint.com', '99designs.com', 'staging.vistaprint.com', 'dev'];

    urlMap.forEach((val, key, map) => {
        // no processing required for the host URL as it's already an absolute URL
        if (ignoredUrlKeys.includes(key)) {
            return;
        }

        // prefixing paths with the host assumes that the path begins with a forward slash
        if (val[0] !== '/') {
            // eslint-disable-next-line no-throw-literal
            throw `URL value: ${val} is missing a beginning forward slash`;
        }

        map.set(key, `${hostUrlValue}${val}`);
    });

    return Object.fromEntries(urlMap);
}

interface TranslationObject {
    [key: string]: string;
}

export const absoluteUrlTranslations = {
    dadkURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', dadkURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', dadkURLs['paths']['99designs']),
    },
    deatURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', deatURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', deatURLs['paths']['99designs']),
    },
    dechURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', dechURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', dechURLs['paths']['99designs']),
    },
    dedeURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', dedeURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', dedeURLs['paths']['99designs']),
    },
    enauURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', enauURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', enauURLs['paths']['99designs']),
    },
    encaURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', encaURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', encaURLs['paths']['99designs']),
    },
    engbURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', engbURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', engbURLs['paths']['99designs']),
    },
    enieURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', enieURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', enieURLs['paths']['99designs']),
    },
    eninURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', eninURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', eninURLs['paths']['99designs']),
    },
    ennzURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', ennzURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', ennzURLs['paths']['99designs']),
    },
    ensgURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', ensgURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', ensgURLs['paths']['99designs']),
    },
    enusURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', enusURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', enusURLs['paths']['99designs']),
    },
    esesURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', esesURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', esesURLs['paths']['99designs']),
    },
    esusURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', esusURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', esusURLs['paths']['99designs']),
    },
    fifiURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', fifiURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', fifiURLs['paths']['99designs']),
    },
    frbeURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', frbeURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', frbeURLs['paths']['99designs']),
    },
    frcaURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', frcaURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', frcaURLs['paths']['99designs']),
    },
    frchURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', frchURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', frchURLs['paths']['99designs']),
    },
    frfrURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', frfrURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', frfrURLs['paths']['99designs']),
    },
    itchURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', itchURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', itchURLs['paths']['99designs']),
    },
    ititURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', ititURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', ititURLs['paths']['99designs']),
    },
    nbnoURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', nbnoURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', nbnoURLs['paths']['99designs']),
    },
    nlbeURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', nlbeURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', nlbeURLs['paths']['99designs']),
    },
    nlnlURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', nlnlURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', nlnlURLs['paths']['99designs']),
    },
    ptptURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', ptptURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', ptptURLs['paths']['99designs']),
    },
    svseURLs: {
        ...transformRelativeURLtoAbsoluteURL('vistaprint.com', svseURLs['paths']['vistaprint']),
        ...transformRelativeURLtoAbsoluteURL('99designs.com', svseURLs['paths']['99designs']),
    },
};

export function __url(translationKey: string): string {
    return i18n.t(translationKey);
}
