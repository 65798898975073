import { getNinetyNineHostKey } from './getNinetyNineHostKey';
import { getVistaHostKey } from './getVistaHostKey';

export function getHostKeyBySite(site: string): string {
    switch (site) {
        case '99designs.com':
            return getNinetyNineHostKey();
        case 'vistaprint.com':
            return getVistaHostKey();
        default:
            throw new Error(`Unknown site: ${site}`);
    }
}
