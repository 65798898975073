import React, { useContext } from 'react';
import Bugsnag from '@bugsnag/js';
import { FlexBox, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { BriefContext } from '../BriefContext';
import IconContainer from './components/IconContainer';
import NumberIcon from './components/NumberIcon';
import StepDescriptionBox from './components/StepDescriptionBox';
import StepIconWrapper from './components/StepIconWrapper';
import StepItemContainer from './components/StepItemContainer';
import StepTitleContainer from './components/StepTitleContainer';
import StepsContainer from './components/StepsContainer';
import CommunityStep1 from './images/CommunityStep1.png';
import CommunityStep2 from './images/CommunityStep2.png';
import CommunityStep3 from './images/CommunityStep3.png';
import Step1 from './images/Step1.png';
import Step2 from './images/Step2.png';
import Step3 from './images/Step3.png';

export type HowItWorksStep = {
    title: string;
    description: string;
    image: React.ReactNode;
};

function getSteps(howItWorks?: string): HowItWorksStep[] {
    switch (howItWorks) {
        case 'community-matching-collaborate-hiw':
        case 'bifrost-hiw':
            return [
                {
                    title: __('Describe what you need'),
                    description: __('Fill out this brief with your project details.'),
                    image: (
                        <img
                            src={CommunityStep1}
                            alt={__('step 1')}
                            width={'90%'}
                            height={'auto'}
                        />
                    ),
                },
                {
                    title: __('Select your designer'),
                    description: __(
                        'View designers’ pitches, reviews and portfolios to pick the right one for you.'
                    ),
                    image: (
                        <img
                            src={CommunityStep2}
                            alt={__('step 2')}
                            width={'100%'}
                            height={'auto'}
                        />
                    ),
                },
                {
                    title: __('Your project kicks off'),
                    description: __(
                        'Your selected designer will message you with any questions and concepts.'
                    ),
                    image: (
                        <img
                            src={CommunityStep3}
                            alt={__('step 3')}
                            width={'100%'}
                            height={'auto'}
                        />
                    ),
                },
            ];
        default:
            return [
                {
                    title: __('Describe what you need'),
                    description: __('Fill out this brief with your project details'),
                    image: <img src={Step1} alt={__('step 1')} width={'90%'} height={'auto'} />,
                },
                {
                    title: __('Submit your request'),
                    description: __('Pay for your design & send your brief to our team'),
                    image: <img src={Step2} alt={__('step 2')} width={'100%'} height={'auto'} />,
                },
                {
                    title: __('Receive your design'),
                    description: __('Get a draft from your designer within 24 hours'),
                    image: <img src={Step3} alt={__('step 3')} width={'100%'} height={'auto'} />,
                },
            ];
    }
}

export function HowItWorks({ isModal = false }: { isModal?: boolean }) {
    const ctx = useContext(BriefContext);
    if (!ctx) {
        // While this component should be used inside BriefContext, it's not worth breaking the page if it's not
        Bugsnag.notify(new Error('HowItWorks component used outside of BriefContext'));
    }
    const steps: HowItWorksStep[] = getSteps(ctx?.config?.howItWorks);
    return (
        <div>
            <Typography fontSkin={'title-subsection'} textAlign="left" pr={8} pb={4}>
                {__('How it works')}
            </Typography>
            <StepsContainer isModal={isModal}>
                {steps.map(({ title, image, description }, index) => (
                    <StepItemContainer key={`how-it-works-${index}`} isModal={isModal}>
                        <StepIconWrapper>{image}</StepIconWrapper>
                        <FlexBox flexDirection={'column'} justifyContent={'center'}>
                            <StepTitleContainer isModal={isModal}>
                                <IconContainer>
                                    <NumberIcon num={index + 1} />
                                </IconContainer>
                                <Typography
                                    fontSkin={'body-standard-bold'}
                                    textAlign="left"
                                    marginLeft={2}
                                >
                                    {title}
                                </Typography>
                            </StepTitleContainer>
                            <StepDescriptionBox isModal={isModal}>
                                <Typography fontSkin={'body-small'}>{description}</Typography>
                            </StepDescriptionBox>
                        </FlexBox>
                    </StepItemContainer>
                ))}
            </StepsContainer>
        </div>
    );
}
