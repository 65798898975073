import { PricingContextProvider } from '@vp/react-pricing-context';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ExpressCheckout } from '@vp/om-express-checkout';
import { Spinner } from '@vp/swan';
import { ErrorPage } from '@99designs/briefs';
import { useIdentityContext } from '@99designs/design-services-common';
import { __, getCountryCode, getCurrentCulture } from '@99designs/i18n';
import { getEnvironment, useExpressCart } from '../hooks/useExpressCart';

// As this page is an overlay it needs a different loading screen to the rest of the app
export const FullPageSpinner = () => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(83,92, 111, 0.8)',
            zIndex: 1000,
        }}
    >
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000,
            }}
        >
            <Spinner accessibleText={__('Loading...')} />
        </div>
    </div>
);

export const Checkout: React.FC = () => {
    const { jobId } = useParams();
    const briefId = jobId as string;
    const { SignIn, isSignedIn } = useIdentityContext();
    const result = useExpressCart(briefId);
    if (!isSignedIn) {
        SignIn();
    }

    if (result.type === 'loading') {
        return <FullPageSpinner />;
    }

    if (result.type === 'error') {
        return (
            <ErrorPage
                title={__(
                    'There was an error loading your brief. Please refresh the page or try again later.'
                )}
                error={result.error}
                metadata={{ briefId }}
            />
        );
    }

    return (
        <PricingContextProvider
            initializationConfig={{
                merchantId: 'vistaprint',
                market: getCountryCode(getCurrentCulture()),
                isDevelopmentMode: getEnvironment() !== 'production',
            }}
        >
            <ExpressCheckout {...result.data} />
        </PricingContextProvider>
    );
};
