import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
    OptionStyles,
    ProductOption,
    ProductOptionChangedEvent,
    ProductOptionModel,
    ProductOptionsTranslations,
} from '@vp/product-options-ui';
import {
    Box,
    Column,
    Dropdown,
    DropdownOption,
    FlexBox,
    FluidImage,
    FormError,
    FormLabel,
    GridContainer,
    Row,
    Spinner,
    Typography,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { bugtracker } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';
import { useBriefContext } from '../../../../BriefContext';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { LoaderOverlay } from '../../../../LoaderOverlay';
import { type Field_ClientConfiguredField_Fragment } from '../../../brief.generated';
import { useTrackFieldChange } from '../../../useTrackFieldChange';
import { ClientConfiguredFieldProps } from '../types';
import { useProductOptions } from './ProductOptionsProvider';
import { findProductOptions } from './index';
import { AvailableOptionsType, ProductOptionReviewType, isPrintProductOptionType } from './types';
import useUpdateProductOptionsDropdown from './useUpdateProductOptionsDropdown';
import type { PrintProductOption } from '@99designs/graph-utils/types';

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledDropdown = styled(Dropdown)<{ $loading?: boolean }>`
    pointer-events: ${({ $loading }) => ($loading ? 'none' : undefined)};
`;

const FieldWrapper = styled(Box)<{ opacity?: number }>`
    opacity: ${({ opacity }) => opacity};
    width: 100%;
`;

const MAX_OPTION_VALUES_FOR_BUTTON_SKIN = 4;

export function Input({ id, clientConfiguredValue }: ClientConfiguredFieldProps) {
    const { product } = useBriefContext();
    const { locale } = usePage();

    const {
        availableOptions,
        configuratorLoading,
        configuratorError,
        compatibilityMap,
        setSelectedOptions,
        selectedOptions,
        isProductOptionsFeatureFlagEnabled,
        isValid,
        touched,
        setProductOptionTouched,
    } = useProductOptions();

    useEffect(() => {
        if (
            selectedOptions['Size'] !== 'Custom' &&
            (selectedOptions['Custom Height'] || selectedOptions['Custom Width'])
        ) {
            setSelectedOptions((prev) => ({
                ...prev,
                'Custom Height': '',
                'Custom Width': '',
            }));
        }
    }, [selectedOptions]);

    if (configuratorError || availableOptions.length === 0) {
        return null;
    }

    if (isProductOptionsFeatureFlagEnabled) {
        return (
            <ProductOptionsTranslations locale={locale}>
                <LoaderOverlay loading={configuratorLoading}>
                    <GridContainer>
                        <Row>
                            {availableOptions.map((option) => (
                                <Column
                                    span={checkIfCustomField(option.name) ? 6 : 12}
                                    px={2}
                                    key={option.name}
                                >
                                    <ProductOptionInput
                                        key={option.name}
                                        option={option}
                                        questionId={id}
                                        clientConfiguredValue={clientConfiguredValue}
                                        selectedOptions={selectedOptions}
                                        setSelectedOptions={setSelectedOptions}
                                        isOptionErrored={!isValid(option.key)}
                                        setProductOptionTouched={setProductOptionTouched}
                                        touched={touched}
                                        locale={locale}
                                        currency={product?.pricing.amount.currency ?? ''}
                                    />
                                </Column>
                            ))}
                        </Row>
                    </GridContainer>
                </LoaderOverlay>
            </ProductOptionsTranslations>
        );
    }

    return (
        <>
            {availableOptions.map((option) => (
                <OptionDropdown
                    key={option.name}
                    option={option}
                    compatibilityMap={compatibilityMap}
                    questionId={id}
                    clientConfiguredValue={clientConfiguredValue}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    isOptionErrored={!isValid(option.key)}
                    loading={configuratorLoading}
                    setProductOptionTouched={setProductOptionTouched}
                    touched={touched}
                />
            ))}
        </>
    );
}

const customFieldIds = ['Custom Height', 'Custom Width'];

function checkIfCustomField(name: string) {
    return customFieldIds.includes(name);
}

interface OptionDropdownProps {
    option: AvailableOptionsType;
    compatibilityMap: Record<string, string[]>;
    questionId: string;
    clientConfiguredValue: string;
    selectedOptions: Record<string, string>;
    setSelectedOptions: (options: Record<string, string>) => void;
    isOptionErrored: boolean;
    loading: boolean;
    touched: Record<string, boolean>;
    setProductOptionTouched: (optionKey?: string) => void;
}

function OptionDropdown(props: OptionDropdownProps) {
    const {
        option,
        compatibilityMap,
        questionId,
        clientConfiguredValue,
        selectedOptions,
        setSelectedOptions,
        isOptionErrored,
        loading,
        touched,
        setProductOptionTouched,
    } = props;

    const { briefId } = useBriefFormContext();

    const updateMutation = useUpdateProductOptionsDropdown(
        questionId,
        briefId,
        clientConfiguredValue
    );

    const trackFieldChange = useTrackFieldChange(`${questionId}.${option.key}`);

    // Do not render if option is a custom value. Custom values can only be handled by the ProductOptionInput component.

    if (!isPrintProductOptionType(option)) {
        return null;
    }

    const isOptionSelected = Object.keys(selectedOptions).includes(option.key);

    const handleChange = async (
        option: PrintProductOption,
        event: ChangeEvent<HTMLSelectElement>
    ) => {
        const target = event.target.value;

        const newSelectedOptions = {
            ...selectedOptions,
            [option.key]: target,
        };

        setSelectedOptions(newSelectedOptions);

        await updateMutation(JSON.stringify(newSelectedOptions));
        trackFieldChange();
    };

    const compatibleOptionValues = compatibilityMap[option.key] || [];
    if (!compatibleOptionValues) {
        bugtracker.notify(new Error('no compatible values found for product option'), (event) => {
            event.addMetadata('compatibilityMap', compatibilityMap);
            event.addMetadata('option', option);
        });
    }

    let isOptionValueEmpty = !clientConfiguredValue || clientConfiguredValue === '{}';
    try {
        if (!isOptionValueEmpty) {
            isOptionValueEmpty = JSON.parse(clientConfiguredValue)[option.key] === undefined;
        }
    } catch (e) {
        bugtracker.notify(
            `Can't parse JSON client configured value for product option: ${e}`,
            (event) => {
                event.addMetadata('clientConfiguredValue', [clientConfiguredValue]);
                event.addMetadata('option', [option]);
            }
        );
    }

    return (
        <DropdownWrapper>
            <FieldWrapper opacity={loading && !isOptionSelected ? 0.5 : 1}>
                <FormLabel htmlFor={questionId + option.key} marginBottom={'3'}>
                    {option.name}{' '}
                    <Box as="span" className="swan-label-optional">
                        ({__('Optional')})
                    </Box>
                </FormLabel>
                {touched[option.key] && isOptionErrored && (
                    <Box mb={'4'}>
                        <FormError id={`${questionId}.${option.key}+error`} marginTop={'4'}>
                            {__('This field is required')}
                        </FormError>
                    </Box>
                )}
                <StyledDropdown
                    onChange={(e) => handleChange(option, e)}
                    onBlur={() => setProductOptionTouched(option.key)}
                    value={selectedOptions[option.key] || ''}
                    fullWidth
                    mb={5}
                    loadingShimmer={loading && !isOptionSelected}
                    $loading={loading}
                    aria-invalid={isOptionErrored}
                    ref={(e) => {
                        const element = e as HTMLSelectElement | undefined;
                        if (element && !isOptionErrored) {
                            // This hack removes the error border for individual dropdown, as there may be multiple per question.

                            if (element.className) {
                                element.className = element.className.replace(
                                    'swan-dropdown-skin-error',
                                    ''
                                );
                                element.setAttribute('aria-invalid', 'false');
                            }
                        }
                    }}
                >
                    <DropdownOption
                        key={`${option.key}-empty`}
                        value=""
                        hidden={!isOptionValueEmpty}
                        disabled
                    >
                        {__('Select {{optionTitle}}', {
                            optionTitle: option.summaryName || option.name,
                        })}
                    </DropdownOption>
                    {option.values.map((value) => (
                        <DropdownOption
                            key={`${option.key}-${value.key}`}
                            value={value.key}
                            disabled={!compatibleOptionValues.includes(value.key)}
                        >
                            {value.name}
                        </DropdownOption>
                    ))}
                </StyledDropdown>
            </FieldWrapper>
        </DropdownWrapper>
    );
}

interface ProductOptionInputProps {
    option: AvailableOptionsType;
    questionId: string;
    clientConfiguredValue: string;
    selectedOptions: Record<string, string>;
    setSelectedOptions: (options: Record<string, string>) => void;
    isOptionErrored: boolean;
    touched: Record<string, boolean>;
    setProductOptionTouched: (optionKey?: string) => void;
    locale: string;
    currency: string;
}

function ProductOptionInput(props: ProductOptionInputProps) {
    const {
        option,
        questionId,
        clientConfiguredValue,
        selectedOptions,
        setSelectedOptions,
        isOptionErrored,
        touched,
        setProductOptionTouched,
        locale,
        currency,
    } = props;

    const screenClass = useScreenClass();

    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateProductOptionsDropdown(
        questionId,
        briefId,
        clientConfiguredValue
    );

    const trackFieldChange = useTrackFieldChange(`${questionId}.${option.key}`);

    if (selectedOptions['Size'] !== 'Custom' && checkIfCustomField(option.name)) {
        return null;
    }

    const handleChange = async (event: ProductOptionChangedEvent) => {
        const value = event.choiceKey || '';

        const newSelectedOptions = {
            ...selectedOptions,
            [option.key]: value,
        };

        setSelectedOptions(newSelectedOptions);

        setProductOptionTouched(option.key);

        await updateMutation(JSON.stringify(newSelectedOptions));
        trackFieldChange();
    };

    const style = getStyleForProductOption(option);

    return (
        <Box pb={style === 'buttons' ? 0 : 5}>
            <ProductOption
                productOption={transformOptionToProductOption({
                    option,
                    selectedKey: selectedOptions[option.key],
                    style,
                    screenClass,
                })}
                tenant="vistaprint"
                currency={currency}
                fractionDigits={2}
                locale={locale}
                priceDisplayMode="none"
                showIncompatibleChoices={true}
                skin="standard"
                onChanged={handleChange}
                showFormErrors={touched[option.key] && isOptionErrored}
                uiDensityUpdate={true}
            />
        </Box>
    );
}

function transformOptionToProductOption({
    option,
    selectedKey,
    style,
    screenClass,
}: {
    option: AvailableOptionsType;
    selectedKey: string;
    style: OptionStyles;
    screenClass: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}): ProductOptionModel {
    // Note: isCompatible isn't available if switching over to the Selector API.

    return {
        key: option.key,
        name: option.name,
        style,
        choices: isPrintProductOptionType(option)
            ? option.values
                  .filter((value) => {
                      if (option.key === 'Size' || option.key === 'Unfolded Size') {
                          return value.isCompatible;
                      }

                      return true;
                  })
                  .map((value) => {
                      const image =
                          screenClass !== 'xs' && value.imageUrl ? value.imageUrl : undefined;
                      return {
                          key: value.key,
                          name: value.name,
                          isCompatible: value.isCompatible,
                          image,
                      };
                  })
            : option.ranges,
        unitOfMeasure: isPrintProductOptionType(option) ? undefined : option.unit,
        selectedKey,
    };
}

function getStyleForProductOption(option: AvailableOptionsType) {
    if (option.key === 'Size') {
        return 'dropdown';
    }

    if (!isPrintProductOptionType(option)) return 'textbox';

    return option.values.length > MAX_OPTION_VALUES_FOR_BUTTON_SKIN ? 'dropdown' : 'buttons';
}

function useClientSelectedOptions(clientConfiguredValue: string) {
    const selectedOptions = useMemo(() => {
        try {
            return JSON.parse(clientConfiguredValue);
        } catch (e) {
            const error = e as Error;
            bugtracker.notify(`Failed to parse client configured value: ${error.message}`);
            return {};
        }
    }, [clientConfiguredValue]);
    return selectedOptions;
}

export function ProductOptionsReview({
    clientConfiguredValue,
}: Field_ClientConfiguredField_Fragment) {
    const selectedOptions = useClientSelectedOptions(clientConfiguredValue);
    const { availableOptions, configuratorLoading, configuratorError } = useProductOptions();
    const [customerSelectedOptions, setCustomerSelectedOptions] = useState<
        ProductOptionReviewType[]
    >([]);

    useEffect(() => {
        const matchedProductOptions = availableOptions.filter((option) => {
            return Object.keys(selectedOptions).includes(option.key);
        });

        setCustomerSelectedOptions(
            findProductOptions(selectedOptions, matchedProductOptions, false)
        );
    }, [availableOptions, selectedOptions]);

    if (configuratorLoading) {
        return <Spinner accessibleText={__('Loading...')} />;
    }

    if (configuratorError || customerSelectedOptions.length === 0) {
        return null;
    }

    return (
        <Box>
            {customerSelectedOptions.map((option) => {
                return (
                    <Box key={`review-option-${option.key}`} mb={5}>
                        <Typography fontSkin="body-standard-bold" mb={4}>
                            {option.name}
                        </Typography>
                        <FlexBox alignItems="center">
                            {option.value.imageUrl && (
                                <FluidImage
                                    src={option.value.imageUrl}
                                    alt={option.value.name}
                                    mr={4}
                                    style={{
                                        width: 'auto',
                                        height: '48px',
                                        borderRadius: '4px',
                                    }}
                                />
                            )}
                            <Box>
                                <Typography
                                    fontSkin={
                                        option.value.imageUrl
                                            ? 'body-standard-bold'
                                            : 'body-standard'
                                    }
                                >
                                    {`${option.value.name} ${option.value.unit ?? ''}`}
                                </Typography>
                            </Box>
                        </FlexBox>
                    </Box>
                );
            })}
        </Box>
    );
}
