/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import { BriefRequestFragment } from '../components/BriefForm/brief.generated';

export type SelectChoiceFieldFragmentFragment = {
    __typename: 'SelectChoiceField';
    id: string;
    choiceValue: Types.Maybe<string>;
};

export type MultiSelectChoiceFieldFragmentFragment = {
    __typename: 'MultiSelectChoiceField';
    id: string;
};

export type GridChoiceFieldFragmentFragment = {
    __typename: 'GridChoiceField';
    id: string;
    choiceValue: Types.Maybe<string>;
};

export type RadioChoiceFieldFragmentFragment = {
    __typename: 'RadioChoiceField';
    id: string;
    choiceValue: Types.Maybe<string>;
};

export type MultiChoiceFieldFragmentFragment = { __typename: 'MultiChoiceField'; id: string };

export type BudgetFieldFragmentFragment = { __typename: 'BudgetField'; id: string };

export type DateFieldFragmentFragment = { __typename: 'DateField'; id: string };

export type FilesFieldFragmentFragment = {
    __typename: 'FilesField';
    id: string;
    filesValue: Array<{ __typename: 'StructuredBriefFile'; name: string; key: string }>;
};

export type TextAreaFieldFragmentFragment = {
    __typename: 'TextAreaField';
    id: string;
    textAreaValue: Types.Maybe<string>;
};

export type SquarespacePackageChoiceFieldFragmentFragment = {
    __typename: 'SquarespacePackageChoiceField';
    id: string;
};

export type LogoContestTierChoiceFieldFragmentFragment = {
    __typename: 'LogoContestTierChoiceField';
    id: string;
};

export type ContestUpgradesChoiceFieldFragmentFragment = {
    __typename: 'ContestUpgradesChoiceField';
    id: string;
};

export type PrintServiceOptionsFieldFragmentFragment = {
    __typename: 'PrintServiceOptionsField';
    id: string;
};

export type InputFieldFragmentFragment = { __typename: 'InputField'; id: string };

export type MultiInputFieldFragmentFragment = { __typename: 'MultiInputField'; id: string };

export type MultiGridChoiceFieldFragmentFragment = {
    __typename: 'MultiGridChoiceField';
    id: string;
    multiGridChoiceValue: Array<string>;
};

export type ClientConfiguredFieldFragmentFragment = {
    __typename: 'ClientConfiguredField';
    id: string;
    clientConfiguredValue: string;
};

export type WorkEntityFieldFragmentFragment = {
    __typename: 'WorkEntityField';
    id: string;
    workEntityValue: string;
};

export type ClaimBriefMutationVariables = Types.Exact<{
    id: Types.Scalars['String'];
}>;

export type ClaimBriefMutation = {
    __typename: 'Mutation';
    claimStructuredBrief: {
        __typename: 'StructuredBrief';
        id: string;
        correlationId: string;
        fulfilmentStrategy: Types.Maybe<Types.FulfilmentStrategy>;
        siteKey: Types.Maybe<string>;
        briefHash: string;
        workName: Types.Maybe<string>;
        product: Types.Maybe<{
            __typename: 'ProductFixedPriceProject';
            name: string;
            key: string;
            version: number;
            title: string;
            fulfilmentStrategy: Types.FulfilmentStrategy;
            cimpressProductConfig: Types.Maybe<{
                __typename: 'CimpressProductConfig';
                cimpressProductKey: string;
                cimpressProductVersion: number;
            }>;
            pricing: {
                __typename: 'FixedPrice';
                amount: { __typename: 'Money'; amountInCents: number; currency: string };
            };
        }>;
        request: Types.Maybe<{ __typename: 'Request' } & BriefRequestFragment>;
        dynamicFields: Array<
            | ({ __typename: 'SelectChoiceField' } & SelectChoiceFieldFragmentFragment)
            | ({ __typename: 'MultiSelectChoiceField' } & MultiSelectChoiceFieldFragmentFragment)
            | ({ __typename: 'GridChoiceField' } & GridChoiceFieldFragmentFragment)
            | ({ __typename: 'RadioChoiceField' } & RadioChoiceFieldFragmentFragment)
            | ({ __typename: 'MultiChoiceField' } & MultiChoiceFieldFragmentFragment)
            | ({ __typename: 'BudgetField' } & BudgetFieldFragmentFragment)
            | ({ __typename: 'DateField' } & DateFieldFragmentFragment)
            | ({ __typename: 'FilesField' } & FilesFieldFragmentFragment)
            | ({ __typename: 'TextAreaField' } & TextAreaFieldFragmentFragment)
            | ({
                  __typename: 'SquarespacePackageChoiceField';
              } & SquarespacePackageChoiceFieldFragmentFragment)
            | ({
                  __typename: 'LogoContestTierChoiceField';
              } & LogoContestTierChoiceFieldFragmentFragment)
            | ({
                  __typename: 'ContestUpgradesChoiceField';
              } & ContestUpgradesChoiceFieldFragmentFragment)
            | ({
                  __typename: 'PrintServiceOptionsField';
              } & PrintServiceOptionsFieldFragmentFragment)
            | ({ __typename: 'InputField' } & InputFieldFragmentFragment)
            | ({ __typename: 'MultiInputField' } & MultiInputFieldFragmentFragment)
            | ({ __typename: 'MultiGridChoiceField' } & MultiGridChoiceFieldFragmentFragment)
            | ({ __typename: 'ClientConfiguredField' } & ClientConfiguredFieldFragmentFragment)
            | ({ __typename: 'WorkEntityField' } & WorkEntityFieldFragmentFragment)
        >;
        fulfilment: Types.Maybe<{
            __typename: 'StructuredBriefFulfilment';
            name: string;
            mpvId: string;
            productKey: string;
            size: string;
        }>;
    };
};

export const SelectChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
    ],
};
export const MultiSelectChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const GridChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
    ],
};
export const RadioChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
    ],
};
export const MultiChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const BudgetFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const DateFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const FilesFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filesValue' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const TextAreaFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                ],
            },
        },
    ],
};
export const SquarespacePackageChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const LogoContestTierChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const ContestUpgradesChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const PrintServiceOptionsFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const InputFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const MultiInputFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
    ],
};
export const MultiGridChoiceFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'multiGridChoiceValue' } },
                ],
            },
        },
    ],
};
export const ClientConfiguredFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'clientConfiguredValue' } },
                ],
            },
        },
    ],
};
export const WorkEntityFieldFragmentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                ],
            },
        },
    ],
};
export const ClaimBriefDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ClaimBrief' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'claimStructuredBrief' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'correlationId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fulfilmentStrategy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cimpressProductConfig',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'cimpressProductKey',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'cimpressProductVersion',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pricing' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'amount' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'amountInCents',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'currency',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fulfilmentStrategy' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'request' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BriefRequest' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'siteKey' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'briefHash' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dynamicFields' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'SelectChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'MultiSelectChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'GridChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RadioChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'MultiChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'BudgetFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DateFieldFragment' },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'FilesFieldFragment' },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'TextAreaFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'SquarespacePackageChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'LogoContestTierChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ContestUpgradesChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'PrintServiceOptionsFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InputFieldFragment' },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'MultiInputFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'MultiGridChoiceFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ClientConfiguredFieldFragment',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'WorkEntityFieldFragment',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fulfilment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productKey' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'workName' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filesValue' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'multiGridChoiceValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredFieldFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'clientConfiguredValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityFieldFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BriefRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facts' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'designLiveAppointmentTime' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'designLiveTimezone' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'workflow' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expertService' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'inclusions' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'serviceProduct' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productKey' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pimMerchantVersion' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'printProduct' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'listPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'taxed' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type ClaimBriefMutationFn = Apollo.MutationFunction<
    ClaimBriefMutation,
    ClaimBriefMutationVariables
>;

/**
 * __useClaimBriefMutation__
 *
 * To run a mutation, you first call `useClaimBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimBriefMutation, { data, loading, error }] = useClaimBriefMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClaimBriefMutation(
    baseOptions?: Apollo.MutationHookOptions<ClaimBriefMutation, ClaimBriefMutationVariables>
) {
    return Apollo.useMutation<ClaimBriefMutation, ClaimBriefMutationVariables>(
        ClaimBriefDocument,
        baseOptions
    );
}
export type ClaimBriefMutationHookResult = ReturnType<typeof useClaimBriefMutation>;
export type ClaimBriefMutationResult = Apollo.MutationResult<ClaimBriefMutation>;
export type ClaimBriefMutationOptions = Apollo.BaseMutationOptions<
    ClaimBriefMutation,
    ClaimBriefMutationVariables
>;
