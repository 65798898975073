// Temporary list of unimplemented form fields for bifrost brief products
// We will eventually support all form fields as defined by the brief's schema
export const UNIMPLEMENTED_QUESTION_KEYS_EDITABLE = [
    // logoEssentialV2
    'language',
    'inspirationFiles',
    'inspirationUrls',
    'referenceFiles',
    'timeline',
    'startDate',
    'endDate',
    // brochureEssentialV2, businessCardEssentialV2, postcardFlyerEssentialV2
    'printServiceOptions',
    'agencyType',
    'referralType',
    //logomakerCompletion
    'logoGoals',
];

// Not all CDO editable brief fields are rendered in the read-only view
// Once we show all CDO brief fields in the read-only view, we can remove this list
export const UNIMPLEMENTED_QUESTION_KEYS_READONLY = UNIMPLEMENTED_QUESTION_KEYS_EDITABLE.concat([
    // CARE fields
    'requestType',
    'appointmentTime',
    'physicalProduct',
]);
