import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigationClicked } from './useNavigationClicked';

export function useSteps(stepCount: number, trackStep = true) {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentStep = Number(searchParams.get('step')) || 1;

    const setCurrentStep = useCallback(
        (step: number) => {
            setSearchParams(
                (prevParams) => {
                    const newParams = new URLSearchParams(prevParams);
                    newParams.set('step', String(step));
                    return newParams;
                },
                { replace: false }
            );
        },
        [setSearchParams]
    );

    const { trackNavigationClick } = useNavigationClicked({
        label: 'Content: Bottom Bar',
    });

    useEffect(() => {
        if (!searchParams.get('step') && trackStep) {
            setCurrentStep(1);
        }
    }, [searchParams, setCurrentStep, trackStep]);

    const goToNextStep = () => {
        if (currentStep < stepCount - 1) {
            trackNavigationClick(
                `${window.location.pathname}${window.location.search};${
                    window.location.pathname
                }?step=${currentStep + 1};Bottom Bar;designBriefNextButton`,
                'designBriefNextButton'
            );
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            trackNavigationClick(
                `${window.location.pathname}${window.location.search};${
                    window.location.pathname
                }?step=${currentStep - 1};Bottom Bar;designBriefBackButton`,
                'designBriefBackButton'
            );

            setCurrentStep(currentStep - 1);
        }
    };

    return { currentStep, goToNextStep, goToPreviousStep };
}
