import React from 'react';
import { Button, FlexBox, Icon, responsive, tokensRaw, useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useStepContext } from '../context/StepContext';

const BottomBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${tokensRaw.SwanBaseColorWhite};
    border-top: 1px solid ${tokensRaw.SwanBaseColorGrey100};
    box-shadow: 0 -1px 3px ${tokensRaw.SwanBaseColorBlackOpacity10};
    position: fixed;
    bottom: 0;
    z-index: ${tokensRaw.SwanSemZIndexOverlay};
    width: 100%;
`;

const ResponsiveFlexBox = responsive(FlexBox);

export const BottomBar = ({
    NextAction,
    PreviousAction,
    Information,
    BuyNowAction,
}: {
    NextAction?: React.ComponentType;
    BuyNowAction?: React.ComponentType;
    PreviousAction?: React.ComponentType;
    Information?: React.ComponentType;
}) => {
    const { currentStep, previous, next, totalSteps, validate } = useStepContext();
    const screenClass = useScreenClass();

    const leftGroup = getLeftGroup(currentStep, previous, PreviousAction);
    const rightButton = getRightButton(async () => {
        const isValid = await validate(currentStep);
        if (currentStep === totalSteps || !isValid) {
            return;
        }

        next();
    }, NextAction);

    return (
        <BottomBarContainer>
            <ResponsiveFlexBox
                alignItems="center"
                justifyContent="space-between"
                paddingY={4}
                paddingX={5}
                sm={{
                    paddingX: 7,
                    style: {
                        height: '96px',
                    },
                }}
            >
                {leftGroup}
                <FlexBox alignItems="center">
                    {Information && <Information />}
                    {(!BuyNowAction || screenClass !== 'xs') && rightButton}
                    {BuyNowAction && screenClass !== 'xs' ? <BuyNowAction /> : null}
                </FlexBox>
            </ResponsiveFlexBox>
            {screenClass === 'xs' && BuyNowAction && (
                <ResponsiveFlexBox
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom={4}
                    paddingX={5}
                    sm={{
                        paddingX: 7,
                        style: {
                            height: '96px',
                        },
                    }}
                >
                    {rightButton}
                    <BuyNowAction />
                </ResponsiveFlexBox>
            )}
        </BottomBarContainer>
    );
};

function getLeftGroup(currentStep: number, onClick: () => void, Component?: React.ComponentType) {
    if (Component != null) {
        return <Component />;
    } else if (currentStep !== 1) {
        return (
            <Button
                skin="tertiary"
                onClick={onClick}
                disabled={currentStep === 0}
                iconPosition="left"
            >
                <Icon iconType="arrowLeft" />
                {__('Back')}
            </Button>
        );
    }

    return <span />;
}

function getRightButton(onClick: () => void, Component?: React.ComponentType) {
    if (Component != null) {
        return <Component />;
    }

    return (
        <Button onClick={onClick} skin="primary">
            {__('Next')}
        </Button>
    );
}
