import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StandardForm } from '@vp/swan';
import { __, getSdpUrl } from '@99designs/i18n';
import { MultiStep, StepLayout } from '@99designs/swan-wizard';
import { usePage } from '@99designs/tracking';
import { useBriefContext } from '../BriefContext';
import { BriefFormProvider } from '../BriefContext/BriefFormContext';
import { BriefFormProps, FormInput } from './BriefForm';
import { FieldGroup, fieldGroups } from './FieldGroup';
import { useFormGroups } from './FieldGroup/useFormGroups';
import { FieldProcessingProvider } from './FieldProcessingProvider';
import { useProductOptions } from './FormFields/ClientConfiguredFields/ProductOptions';
import { Information } from './Information';
import { IntroStep } from './IntroStep';
import { ReviewBriefButton } from './ReviewBriefButton';
import { ViewContextProvider } from './ViewContext';
import { useRegisterWithTracking } from './useRegisterWithTracking';

function useIsStepValid(
    groups: ReturnType<typeof fieldGroups>,
    trigger: (name?: string | string[]) => Promise<boolean>
) {
    const { validate, setAllProductOptionsTouched } = useProductOptions();
    return useCallback(
        async (currentStep: number) => {
            // We subtract 1 from the current step because we start the steps at 1
            const group = groups[currentStep - 1];
            if (!group) {
                return true;
            }

            // We assume here that product options is always in a group of its own
            const productOptionField = group.fields
                .filter((field) => field.id === 'productOptions')
                .pop();
            if (productOptionField) {
                const isValid = await validate();
                if (!isValid) {
                    setAllProductOptionsTouched();
                    return false;
                }
            }

            return await trigger(group.fields.map((f) => f.id));
        },
        [groups, trigger, validate, setAllProductOptionsTouched]
    );
}

export const MultiStepBriefForm: React.FC<Pick<BriefFormProps, 'brief'>> = ({ brief }) => {
    const { intro, groups, workEntityField } = useFormGroups(brief);
    const { pageData } = usePage();
    const { product } = useBriefContext();

    const methods = useForm<FormInput>({
        shouldUnregister: true,
    });
    const {
        register,
        setValue,
        watch,
        clearErrors,
        setError,
        formState: { errors: formErrors, dirtyFields },
        getValues,
        trigger,
    } = methods;

    const registerWithTracking = useRegisterWithTracking(
        brief,
        register,
        getValues,
        dirtyFields,
        pageData
    );

    const steps = [
        <IntroStep
            key="intro"
            product={product ?? null}
            workEntity={workEntityField}
            group={intro}
            step={
                <FieldGroup
                    key={intro.id}
                    group={{
                        ...intro,
                        fields: intro.fields.filter((f) => f.__typename !== 'WorkEntityField'),
                    }}
                    register={registerWithTracking}
                    errors={formErrors}
                    setValue={setValue}
                    watch={watch}
                    clearErrors={clearErrors}
                    setError={setError}
                    isIntro={true}
                    titleFontSkin={'body-standard-bold'}
                />
            }
        />,
        ...groups.map((g, index) => (
            <StepLayout
                key={g.id}
                NextAction={index === groups.length - 1 ? () => <ReviewBriefButton /> : undefined}
                Information={() => <Information product={product} />}
            >
                <FieldGroup
                    key={g.id}
                    group={g}
                    register={registerWithTracking}
                    errors={formErrors}
                    setValue={setValue}
                    watch={watch}
                    isIntro={false}
                    clearErrors={clearErrors}
                    setError={setError}
                />
            </StepLayout>
        )),
    ];
    const allGroups = useMemo(() => [intro, ...groups], [intro, groups]);
    const validate = useIsStepValid(allGroups, trigger);

    if (brief.groups === undefined) {
        return null;
    }

    return (
        <StandardForm style={{ height: '100%' }}>
            <FieldProcessingProvider>
                <ViewContextProvider value={{ isMultiStep: true }}>
                    <BriefFormProvider briefId={brief.id}>
                        <FormProvider {...methods}>
                            <MultiStep
                                steps={steps}
                                validate={validate}
                                title={brief.product?.title ?? __('Custom Design')}
                                exitUrl={
                                    brief.product?.fulfilmentMpvId
                                        ? getSdpUrl(brief.product.fulfilmentMpvId)
                                        : '/design'
                                }
                                trackSteps
                            />
                        </FormProvider>
                    </BriefFormProvider>
                </ViewContextProvider>
            </FieldProcessingProvider>
        </StandardForm>
    );
};
