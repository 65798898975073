import { Link, P } from '@vp/swan';
import { FormattedReactMessage } from '@99designs/design-services-common';
import { __, asNinetyNineAbsUrl } from '@99designs/i18n';
import { sendNavigationEvent, usePage } from '@99designs/tracking';

const dtaClickEventTracking = {
    label: 'Design Transfer Agreement Viewed',
    eventDetail: '/design/briefs/create;/c;BriefForm;DesignTransferAgreement-CTA',
    navigationDetail: 'DesignTransferAgreement-CTA',
    category: 'Navigation Clicked',
};

export const TermsAndConditions = () => {
    const { pageData } = usePage();

    return (
        <P mt={'7'}>
            <FormattedReactMessage
                message={__(
                    'By adding to cart and completing this purchase, you agree to the terms of the <Link>design transfer agreement</Link>.'
                )}
                replacements={{
                    Link: (
                        <Link
                            onClick={() =>
                                sendNavigationEvent({
                                    ...dtaClickEventTracking,
                                    ...pageData,
                                })
                            }
                            target={'_blank'}
                            href={asNinetyNineAbsUrl('/legal/design-transfer-agreement')}
                        />
                    ),
                }}
            />
        </P>
    );
};
