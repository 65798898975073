import { useEffect, useState } from 'react';
import { Dropdown, DropdownOption as SwanDropdownOption } from '@vp/swan';
import type { ChangeEvent } from 'react';
import { Currency, Price, bugtracker, useIdentityContext } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';
import { sendProductSelectedTrackingEvent } from '@99designs/tracking';
import { useCart } from '../../../../../lib/hooks/useCart';
import { useBriefContext } from '../../../../BriefContext/BriefContext';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { useProductOptions } from '../ProductOptions';
import { ClientConfiguredFieldProps } from '../types';
import { useServiceProductDetailsLazyQuery, useTopProductsQuery } from './products.generated';
import useUpdateProductDropdown from './useUpdateProductDropdown';
import {
    Product,
    RecentlySelectedProduct,
    categoryBriefMap,
    mapCategoriesToProducts,
    transformBriefProductToProduct,
    transformProductToBriefProduct,
} from './utils';

export function Input({
    id,
    clientConfiguredValue,
    setValue,
    register,
}: ClientConfiguredFieldProps) {
    const { locale, pageData } = usePage();
    const { setSelectedOptions, setProductOptionTouched } = useProductOptions();

    const { accessToken, shopperId, isSignedIn } = useIdentityContext();
    const {
        updateProduct,
        product: productFromBriefContext,
        recentProducts,
        addRecentProduct,
        isUpdatingProduct,
        setIsUpdatingProduct,
    } = useBriefContext();
    const { briefId } = useBriefFormContext();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    // MUTATIONS

    const updateMutation = useUpdateProductDropdown(id, briefId, clientConfiguredValue);

    // QUERIES

    const [getServiceProductDetails] = useServiceProductDetailsLazyQuery({ errorPolicy: 'all' });
    const { data, loading: isTopProductsQueryLoading } = useTopProductsQuery();
    const { isInCart } = useCart(locale, { accessToken, shopperId });

    useEffect(() => {
        async function getCartData() {
            const briefInCart = await isInCart(briefId as string);
            setIsDisabled(briefInCart);
        }
        if (isSignedIn) {
            void getCartData();
        }
    }, [briefId, isInCart, isSignedIn]);

    // LOGIC

    useEffect(() => {
        if (
            productFromBriefContext &&
            !recentProducts
                ?.map((product) => product.mpvId.toLowerCase())
                .includes(productFromBriefContext.fulfilmentMpvId?.toLowerCase() || '')
        ) {
            addRecentProduct(transformBriefProductToProduct(productFromBriefContext));
        }
    }, [addRecentProduct, productFromBriefContext, recentProducts]);

    useEffect(() => {
        setIsLoading(isTopProductsQueryLoading || isUpdatingProduct);
    }, [isTopProductsQueryLoading, isUpdatingProduct]);

    if (!data) {
        return (
            <Dropdown key="loading-dropdown-msb" fullWidth mb={5} loadingShimmer disabled>
                <SwanDropdownOption key={`${id}-empty-loading`} value="" hidden>
                    {__('Select product')}
                </SwanDropdownOption>
            </Dropdown>
        );
    }

    const [sortedCategoryMap, productMap] = mapCategoriesToProducts(data);

    const handleChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const target = event.target.value;

        setIsUpdatingProduct(true);

        const maybeTopProductHit = productMap.get(target);
        const selectedProduct = maybeTopProductHit
            ? maybeTopProductHit
            : recentProducts?.find(
                  (product: RecentlySelectedProduct) =>
                      product.mpvId.toLowerCase() === target.toLowerCase()
              );

        if (!selectedProduct) {
            bugtracker.notify(new Error('cannot get selected product'), (event) => {
                event.addMetadata('debug info', 'mpvID', target);
            });

            return null;
        }

        addRecentProduct(selectedProduct);

        const { data: productDetails, error } = await getServiceProductDetails({
            variables: {
                mpvId: target,
            },
        });

        if (error)
            bugtracker.notify(
                new Error(
                    `Product Details unable to be retrieved. Error message: ${error.message}`
                ),
                (event) => {
                    event.addMetadata('debug info', 'mpvID', target);
                }
            );

        const updatedProduct = transformProductToBriefProduct(
            selectedProduct,
            productDetails?.serviceDetailPage.inclusions
        );

        if (updatedProduct) {
            await updateMutation(target);

            // Warning: as the product is not read from the brief Apollo query after the first
            // time, it is important to manually update the product in the brief context
            // whenever it can be changed.

            updateProduct(updatedProduct);
            setSelectedOptions({});
            setProductOptionTouched();

            sendProductSelectedTrackingEvent({
                ...pageData,
                category: 'Design Services',
            });
        }

        setValue(id, target && target.toLowerCase());

        setIsUpdatingProduct(false);
    };

    return (
        <Dropdown
            fullWidth
            mb="5"
            defaultValue={clientConfiguredValue && clientConfiguredValue.toLowerCase()}
            {...register(id, {
                required: __('This field is required'),
                onChange: handleChange,
                disabled: isDisabled || isLoading,
            })}
            data-testid="product-dropdown"
            loadingShimmer={isLoading}
        >
            <SwanDropdownOption
                key={`${id}-empty`}
                value=""
                hidden={!!clientConfiguredValue}
                disabled
            >
                {__('Select product')}
            </SwanDropdownOption>
            {Array.from(sortedCategoryMap.keys()).map((category) => (
                <optgroup key={category} label={categoryBriefMap(category) || category}>
                    {sortedCategoryMap.get(category)?.map((product: Product, index: number) => (
                        <DropdownOption product={product} index={index} key={index} />
                    ))}
                </optgroup>
            ))}
            <optgroup label={__('Recently Selected')}>
                {recentProducts?.map((product: RecentlySelectedProduct, index: number) => (
                    <DropdownOption product={product} index={index} key={index} />
                ))}
            </optgroup>
        </Dropdown>
    );
}

const DropdownOption = ({
    product,
    index,
}: {
    product: Product | RecentlySelectedProduct;
    index: number;
}) => {
    return (
        <SwanDropdownOption key={`${product.mpvId}-${index}`} value={product.mpvId.toLowerCase()}>
            {product.name}
            {` - `}
            <Price
                amount={product.pricing.amountInCents}
                currency={product.pricing.currency as Currency}
            />
        </SwanDropdownOption>
    );
};
