import { useStatsigClient } from '@statsig/react-bindings';
import { createContext, useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { PrecomputedEvaluationsInterface } from '@statsig/client-core';
import { getTestUserId, isFeatureEnabled, waitTillAvailable } from '@vp/ab-reader';
import { Box, FlexBox, Spinner } from '@vp/swan';
import { useIdentityContext } from '@99designs/design-services-common';
import { __, getCurrentCulture } from '@99designs/i18n';
import { useCanUseExpressCheckoutLazyQuery } from '../graph';

type expressCheckoutGroups = 'test' | 'control' | null;

type BriefExperimentsContextType = {
    expressCheckoutGroup: expressCheckoutGroups;
    productOptionsEnabled: boolean;
};

export const BriefExperimentsContext = createContext<BriefExperimentsContextType>({
    expressCheckoutGroup: null,
    productOptionsEnabled: false,
});

const ALL_DISABLED: BriefExperimentsContextType = {
    expressCheckoutGroup: null,
    productOptionsEnabled: false,
};

const ALL_ENABLED: BriefExperimentsContextType = {
    expressCheckoutGroup: 'test',
    productOptionsEnabled: true,
};

async function getExperiments(
    client: PrecomputedEvaluationsInterface,
    canUseExpressCheckout: boolean,
    locale: string
): Promise<BriefExperimentsContextType> {
    const available = await waitTillAvailable(3000);
    if (!available) {
        Bugsnag.notify(new Error('AB reader is not available'));
        return ALL_DISABLED;
    }
    if (process.env.VITE_BRIEFS_EXPERIMENTS_ENABLED === 'true') {
        return ALL_ENABLED;
    }

    await client.updateUserAsync({
        userID: getTestUserId() ?? undefined,
        userAgent: navigator.userAgent,
        custom: {
            canUseExpressCheckout: canUseExpressCheckout.toString(),
            culture: locale,
        },
    });

    const expressCheckoutGroup = client.getExperiment('es__express_checkout', {
        disableExposureLog: true,
    }).groupName;

    return {
        expressCheckoutGroup: expressCheckoutGroup as expressCheckoutGroups,
        productOptionsEnabled: isFeatureEnabled('expert_services_product_options'),
    };
}

export function useBriefExperiments() {
    return useContext(BriefExperimentsContext);
}

export function fromUrlParams(urlParams: URLSearchParams): BriefExperimentsContextType | undefined {
    const briefExperiments = urlParams.get('brief_experiments');
    if (briefExperiments) {
        try {
            const parsedExperiments = JSON.parse(decodeURIComponent(briefExperiments));
            return {
                expressCheckoutGroup: parsedExperiments.expressCheckoutGroup ?? null,
                productOptionsEnabled: parsedExperiments.productOptionsEnabled ?? false,
            };
        } catch (error) {
            Bugsnag.notify(new Error('Failed to parse brief_experiments from URL parameters'));
        }
    }
}

export function BriefExperimentsProvider({ children }: { children: React.ReactNode }) {
    const [experiments, setExperiments] = useState<BriefExperimentsContextType | null>(null);
    const { shopperId } = useIdentityContext();
    const locale = getCurrentCulture();
    const { client } = useStatsigClient();
    const [canUseExpressCheckOut] = useCanUseExpressCheckoutLazyQuery();

    useEffect(() => {
        const briefExperiments = fromUrlParams(new URLSearchParams(window.location.search));
        if (briefExperiments) {
            setExperiments(briefExperiments);
            return;
        }

        const fetchExperiments = async () => {
            const { data } = await canUseExpressCheckOut();
            const canUseExpressCheckout = data?.canUseExpressCheckout ?? false;

            getExperiments(client, canUseExpressCheckout, locale)
                .then((data) => setExperiments(data))
                .catch((e) => {
                    Bugsnag.notify(e);
                    setExperiments(ALL_DISABLED);
                });
        };

        fetchExperiments();
    }, [canUseExpressCheckOut, client, locale, shopperId]);

    if (!experiments) {
        return (
            <FlexBox justifyContent="center" alignItems="center" style={{ height: '100vh' }} mx={4}>
                <Box my={2}>
                    <Spinner accessibleText={__('Loading...')} />
                </Box>
            </FlexBox>
        );
    }
    return (
        <BriefExperimentsContext.Provider value={experiments}>
            {children}
        </BriefExperimentsContext.Provider>
    );
}
