import { useEffect } from 'react';
import {
    FieldErrors,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { P, StyleFontSkin, Typography, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { getRequestType } from '../../Submit/requestDataFromBrief';
import { FormInput } from '../BriefForm';
import { Field, FieldDisplay, FieldDisplayWrapper, FieldSet, isFieldShown } from '../Field';
import decisionFromBrief from '../FormFields/ClientConfiguredFields/ProductOptions/decisionFromBrief';
import { useIsReviewMode } from '../ViewContext';
import { FieldFragment, StructuredBriefFragment } from '../brief.generated';
import {
    UNIMPLEMENTED_QUESTION_KEYS_EDITABLE,
    UNIMPLEMENTED_QUESTION_KEYS_READONLY,
} from '../questions';
import { useGroupTitleWithOverrides } from './useGroupTitleWithOverrides';

export class FieldGroupData implements FieldGroupData {
    public static create({
        id,
        title,
        description,
        fields,
        isIntro,
    }: {
        id: string;
        title: string;
        description: string | null;
        fields: FieldFragment[];
        isIntro?: boolean;
    }) {
        return new FieldGroupData(id, title, description, fields, isIntro);
    }

    constructor(
        public id: string,
        public title: string,
        public description: string | null,
        public fields: FieldFragment[],
        public isIntro = false
    ) {}

    hasProductQuestion = () => {
        return this.fields.some((field) => field.id === 'physicalProduct');
    };

    shouldShowIntroGroupHeading = (isReviewMode = false) => {
        return isReviewMode || !this.isIntro || this.hasProductQuestion();
    };
}

type FieldGroupProps = {
    group: FieldGroupData;
    errors: FieldErrors<FormInput>;
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    watch: UseFormWatch<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
    setError: UseFormSetError<FormInput>;
    titleFontSkin?: StyleFontSkin;
    isIntro?: boolean;
};

export const Legend = styled.legend`
    font-size: 1.5rem;
    font-weight: 700;
    padding: ${tokensRaw.SwanSemSpace4} 0 ${tokensRaw.SwanSemSpace4} 0;
`;

export function FieldGroup({
    group,
    register,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
    titleFontSkin = 'title-headline',
    isIntro = false,
}: FieldGroupProps) {
    const isReviewMode = useIsReviewMode();
    const title = useGroupTitleWithOverrides(group);
    if (!group.fields.length) return null;
    const isDesignLive = watch('requestType', getRequestType(group.fields)) === 'DesignLive';
    const hasDecisionQuestion = group.fields.some((field) => field.id === 'productOptionsDecision');
    const hasProductOptions =
        hasDecisionQuestion &&
        watch('productOptionsDecision', decisionFromBrief(group.fields)) === 'CUSTOMER';

    return (
        <FieldSet>
            {group.shouldShowIntroGroupHeading(isReviewMode) && (
                <Typography fontSkin={titleFontSkin} id={group.id} mb={isReviewMode ? 5 : 3}>
                    {title}
                </Typography>
            )}
            {group.description && (
                <P fontSkin="body-standard" mt="0" mb="4">
                    {group.description}
                </P>
            )}
            {group.fields.map((f) => {
                // These checks help "related fields" disappear immediately before waiting for mutations to complete.

                if (!isDesignLive && f.id === 'appointmentTime') {
                    return null;
                } else if (hasDecisionQuestion && !hasProductOptions && f.id === 'productOptions') {
                    return null;
                }

                // Those groups also need to have consistent padding even when inputs change.

                const isConditionField =
                    f.id === 'requestType' || f.id === 'productOptionsDecision';
                const isMultiFieldGroup = group.fields.length > 1 || isConditionField;

                return (
                    <Field
                        key={`form-field-${f.id}`}
                        register={register}
                        field={f}
                        error={errors[f.id]}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        setError={setError}
                        isMultiFieldGroup={isMultiFieldGroup}
                        isIntro={isIntro}
                        isOmitRequiredIndicator={isConditionField}
                    />
                );
            })}
        </FieldSet>
    );
}

type FieldGroupDisplayProps = {
    group: FieldGroupData;
    colWidthOverride?: NonNullable<1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
};

export function FieldGroupDisplay({ group, colWidthOverride }: FieldGroupDisplayProps) {
    if (!group.fields.length) {
        return null;
    }

    const isGroupShown = group.fields.some((f) => isFieldShown(f));

    return isGroupShown ? (
        <FieldDisplayWrapper title={group.title}>
            {group.fields.map((f) => {
                return (
                    <FieldDisplay
                        colWidthOverride={colWidthOverride}
                        key={`field-display-${f.id}`}
                        field={f}
                    />
                );
            })}
        </FieldDisplayWrapper>
    ) : null;
}

export enum FieldGroupVariant {
    EDITABLE,
    READONLY,
}

export function fieldGroups(
    brief: StructuredBriefFragment,
    variant: FieldGroupVariant = FieldGroupVariant.EDITABLE
) {
    const unimplementedQuestionKeys =
        variant === FieldGroupVariant.READONLY
            ? UNIMPLEMENTED_QUESTION_KEYS_READONLY
            : UNIMPLEMENTED_QUESTION_KEYS_EDITABLE;
    return brief.groups.map((group) => {
        const fields = brief.dynamicFields.filter(
            (field) => field.groupId === group.id && !unimplementedQuestionKeys.includes(field.id)
        );

        return new FieldGroupData(group.id, group.title, group.description, fields);
    });
}
