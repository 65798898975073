import { StatsigProvider } from '@statsig/react-bindings';
import React, { ReactNode } from 'react';

export function StatsigConfiguration({ children }: { children: ReactNode }) {
    return (
        <StatsigProvider
            sdkKey={process.env.VITE_STATSIG_SERVER_KEY || ''}
            user={{
                userID: '',
            }}
        >
            {children}
        </StatsigProvider>
    );
}
