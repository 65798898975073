import { useEffect, useRef } from 'react';
import { isChatAnywhereEnabled } from '@99designs/care';
import { __ } from '@99designs/i18n';
import { usePage } from '../PageContext';
import { PAGE_DEPT, PAGE_TRACKING_CONFIG } from './consts';
import type {
    BaseTracking,
    PageTracking,
    ProductTracking,
    SearchResultsTracking,
    SearchTracking,
} from './types';

function sendTrack(action: string, event: BaseTracking | ProductTracking | SearchResultsTracking) {
    if (window?.tracking?.track) {
        window.tracking.track(action, { ...event, pageDept: PAGE_DEPT });
    } else {
        setTimeout(() => {
            if (window?.tracking?.track) {
                window.tracking.track(action, { ...event, pageDept: PAGE_DEPT });
            }
        }, 1000);
    }
}

export function sendPopUpView(event: BaseTracking) {
    sendTrack('Pop-Up Viewed', {
        ...event,
        category: 'Pop-Up',
    });
}

export function sendPopUpClose(event: BaseTracking) {
    sendTrack('Pop-Up Closed', {
        ...event,
        category: 'Pop-Up',
    });
}

export function sendNavigationEvent(event: BaseTracking) {
    sendTrack('Navigation Clicked', event);
}

export function sendExperimentClickTrackingEvent(
    event: BaseTracking & {
        experimentName: string;
        variationName?: string;
        experimentDetail?: string;
    }
) {
    sendTrack('Experiment Clicked', event);
}

export function useSendPageViewOnce(locale: string, isImplicitPath = true) {
    const eventSent = useRef(false);

    const {
        pageData: { pageName, pageStage, pageSection },
    } = usePage();

    useEffect(() => {
        const payload = {
            pageName,
            pageStage,
            pageSection,
            pageDept: PAGE_DEPT,
            ...(isImplicitPath
                ? {}
                : {
                      url: window.location.pathname,
                  }),
        };

        if (!eventSent.current) {
            sendPageTracking(payload, locale);
            eventSent.current = true;
        }
    }, [isImplicitPath, locale, pageName, pageSection, pageStage]);

    return null;
}

export function sendPageTracking(pageTracking: PageTracking, locale: string) {
    if (typeof window !== 'undefined') {
        if (window?.tracking?.page) {
            window.tracking.page({
                ...pageTracking,
                // Splits a locale string into language and locale parts
                language: locale.slice(0, 2),
                locale: locale.slice(3, 5),
            });
        } else {
            setTimeout(() => sendPageTracking(pageTracking, locale), 1000);
        }
    }
}

export function sendChatNowTrackingEvent(event: Omit<BaseTracking, 'label'> & { locale: string }) {
    if (typeof window !== 'undefined' && isChatAnywhereEnabled(event.locale)) {
        if (!window.$Lightning) {
            const currentButton = window.document.getElementById('onlineBtn') as HTMLButtonElement;
            if (currentButton) {
                currentButton.innerText = __('Preparing chat...');
                currentButton.disabled = true;
                window.isChatNowClicked = true;
            }
        }
        const EVENT_NAME = 'initCAfromExternalSource';
        const data = {
            eventName: EVENT_NAME,
            timestamp: Date.now(),
            button: 'design-chat-now',
        };
        document.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: data }));
    }

    sendTrack('Chat Now Clicked', {
        ...event,
        category: 'Global Chat',
        label: 'Chat help initiated',
    });
}

export function sendAddToCartTrackingEvent(
    event: Omit<ProductTracking, 'quantity' | 'sales_quantity' | 'discount' | 'list_price'>
) {
    sendTrack('Product Added', {
        ...event,
        label: 'Design Services Added',
        list_price: event.price,
        quantity: 1, // OOB field; set to 1
        sales_quantity: 1, // quantity in the set of product
        discount: 0, // the discount amount,
        currency: event.currency,
    });
}

export function sendProductSelectedTrackingEvent(event: Omit<BaseTracking, 'label'>) {
    sendTrack('Product Brief Edited', {
        ...event,
        label: 'Print Brief:Product Selected',
    });
}

export function sendProductViewedTrackingEvent(
    event: Omit<ProductTracking, 'quantity' | 'sales_quantity' | 'discount' | 'list_price'>
) {
    const { pageSection, pageStage, pageDept } = PAGE_TRACKING_CONFIG['product-page'];
    sendTrack('Product viewed', {
        ...event,
        pageSection,
        pageStage,
        pageDept,
        label: 'Product page view',
        quantity: 1, // OOB field; set to 1
        list_price: event.price,
        sales_quantity: 1, // quantity in the set of product
        discount: 0, // the discount amount
    });
}

export function sendDesignBriefEditedTrackingEvent(properties: DesignBriefEditedProperties) {
    sendTrack('Design Brief Edited', properties);
}

export function sendProductSearchResultClickedTrackingEvent(event: SearchTracking) {
    sendTrack('Search Result Clicked', event);
}

export function sendProductSearchEngagedTrackingEvent(event: BaseTracking) {
    sendTrack('Search Bar Engaged', event);
}

export function sendProductSearchResultsDisplayedTrackingEvent(event: SearchResultsTracking) {
    sendTrack('Search Result Section', event);
}

export function sendPersonalisationRetrievedTrackingEvent(event: BaseTracking) {
    sendTrack('Personalisation Retrieved', event);
}
