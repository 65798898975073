import { useState } from 'react';
import {
    Box,
    FlexBox,
    Icon,
    Link,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Typography,
    responsive,
} from '@vp/swan';
import { Currency, Price } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { NormalisedBriefProduct } from '../BriefContext';
import { HowItWorks } from '../HowItWorks/HowItWorks';
import { StepProductCard } from './StepProductCard';
import { BriefProductFragment } from './brief.generated';

const ResponsiveFlexBox = responsive(FlexBox);
const ResponsiveTypography = responsive(Typography);

export function Information({
    product,
}: {
    product?: BriefProductFragment | NormalisedBriefProduct | null;
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (product) {
        const pricing = product.pricing.amount;
        return (
            <>
                <ResponsiveFlexBox
                    alignItems="center"
                    mr={5}
                    sm={{
                        mr: 7,
                    }}
                >
                    <ResponsiveTypography fontSkin="title-section" mr={3} sm={{ mr: 4 }}>
                        <Price
                            amount={pricing.amountInCents}
                            currency={pricing.currency as Currency}
                            decimalPlaces={2}
                        />
                    </ResponsiveTypography>
                    <Link withIcon onClick={() => setIsModalOpen(!isModalOpen)}>
                        <Icon iconType="info" size="24p" />
                    </Link>
                </ResponsiveFlexBox>
                <InformationModal
                    product={product}
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                />
            </>
        );
    }

    return null;
}

interface InformationModalProps {
    product: BriefProductFragment | NormalisedBriefProduct;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

function InformationModal(props: InformationModalProps) {
    return (
        <ModalDialog isOpen={props.isOpen} onRequestDismiss={() => props.setIsOpen(false)}>
            <ModalDialogContent aria-labelledby="modal dialog general" style={{ width: '600px' }}>
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={__('Close')} />
                </ModalDialogNav>
                <ModalDialogHeader>
                    <ModalDialogTitle>{__("What's included")}</ModalDialogTitle>
                </ModalDialogHeader>
                <ModalDialogBody>
                    <StepProductCard product={props.product} />
                    <Box mt={7}>
                        <HowItWorks isModal />
                    </Box>
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
}
