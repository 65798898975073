import type { ClaimBriefMutation } from '../../graph';

export function getWorkIdFromDynamicFields(
    dynamicFields: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): string | undefined {
    return dynamicFields
        .flatMap((df) => {
            if (df.__typename === 'WorkEntityField') {
                return [df.workEntityValue];
            }
            return [];
        })
        .pop();
}
