import { useStatsigClient } from '@statsig/react-bindings';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PrecomputedEvaluationsInterface } from '@statsig/client-core';
import {
    Box,
    Button,
    Card,
    Column,
    GridContainer,
    H1,
    Icon,
    Row,
    Typography,
    useScreenClass,
} from '@vp/swan';
import { useIdentityContext } from '@99designs/design-services-common';
import { Maybe } from '@99designs/graph-utils/types';
import { __, getCurrentCulture } from '@99designs/i18n';
import { StepLayout, useNavigationClicked } from '@99designs/swan-wizard';
import { BriefExperimentsContext, fromUrlParams, useBriefExperiments } from '../../context';
import { useCart } from '../../lib/hooks';
import { BriefProvider, NormalisedBriefProduct, useBriefContext } from '../BriefContext';
import { EditFormGroupModal } from './EditFormGroupModal';
import { FieldReview } from './Field';
import { FieldGroupData, FieldGroupVariant } from './FieldGroup';
import { useFormGroups } from './FieldGroup/useFormGroups';
import { reviewGroupTitleWithOverrides } from './FieldGroup/useGroupTitleWithOverrides';
import { ProductOptionsProvider } from './FormFields/ClientConfiguredFields/ProductOptions';
import { Information } from './Information';
import { StepProductCard } from './StepProductCard';
import { SubmitMultiStepBriefButton } from './SubmitBriefButton';
import { TermsAndConditions } from './TermsAndConditions';
import { ViewContextProvider, useIsEditMode } from './ViewContext';
import {
    BriefProductFragment,
    BriefRequestFragment,
    Field_WorkEntityField_Fragment,
    StructuredBriefFragment,
} from './brief.generated';

function useHandlePrevious(groupsLength: number) {
    const nav = useNavigate();
    const { trackNavigationClick } = useNavigationClicked({
        label: 'Content: Bottom Bar',
    });

    const handlePrevious = useCallback(() => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace(/\/review$/, '');
        const url = new URL(newPath, window.location.origin);
        url.searchParams.set('step', groupsLength.toString());
        trackNavigationClick(
            `${window.location.pathname}${window.location.search};${
                url.pathname + url.search
            };Bottom Bar;designBriefBackButton`,
            'designBriefBackButton'
        );
        nav(url.pathname + url.search);
    }, [groupsLength, nav, trackNavigationClick]);

    return handlePrevious;
}

// TODO: Add real implementation
function useHandleBuyNow() {
    const nav = useNavigate();
    return useCallback(() => {
        nav('express-checkout');
    }, [nav]);
}

let called = false;

const expressCheckoutFromUrlParams =
    typeof window !== 'undefined'
        ? fromUrlParams(new URLSearchParams(window.location.search))?.expressCheckoutGroup
        : undefined;

const fireBuyNowImpression = (client: PrecomputedEvaluationsInterface): undefined => {
    if (
        !called &&
        process.env.VITE_BRIEFS_EXPERIMENTS_ENABLED !== 'true' &&
        !expressCheckoutFromUrlParams
    ) {
        // This is the recommended approach to fire impression using Statsig
        client.getExperiment('es__express_checkout');
        called = true;
    }
    return undefined;
};

export type ReadOnlyReviewProps = {
    groups: FieldGroupData[];
    product: Maybe<BriefProductFragment | NormalisedBriefProduct>;
};

// This component is required to trigger the product options loading
// as they can only load after the brief has been loaded by a seperate query
// In the future we should refactor this so that the configurator is a field resolver
// on the brief and can access it's parameters from the brief state on the back end and not require multiple queries
const ReadOnlyReviewInner = ({
    brief,
}: {
    brief: StructuredBriefFragment & {
        request: BriefRequestFragment | null;
        product: BriefProductFragment | null;
    };
}) => {
    const { onCompleted } = useBriefContext();
    useEffect(() => {
        if (!brief) {
            return;
        }
        onCompleted({ __typename: 'Query', brief });
    }, [brief, onCompleted]);
    const { intro, groups } = useFormGroups(brief, FieldGroupVariant.READONLY);

    return (
        <>
            {[...(intro ? [intro] : []), ...groups].map((group) => (
                <ReviewCardReadOnly key={`${group.id}-card`} group={group} />
            ))}
        </>
    );
};

export function ReadOnlyReview({
    brief,
    isCollaborationMode,
}: {
    brief: StructuredBriefFragment & {
        request: BriefRequestFragment | null;
        product: BriefProductFragment | null;
    };
    isCollaborationMode: boolean;
}) {
    return (
        <BriefExperimentsContext.Provider
            value={{ productOptionsEnabled: true, expressCheckoutGroup: 'test' }}
        >
            <BriefProvider>
                <ProductOptionsProvider>
                    <ViewContextProvider value={{ isReviewMode: true, isCollaborationMode }}>
                        <ReadOnlyReviewInner brief={brief} />
                    </ViewContextProvider>
                </ProductOptionsProvider>
            </BriefProvider>
        </BriefExperimentsContext.Provider>
    );
}

export function Review({
    groups,
    product,
    workEntity,
    groupFormSections,
    briefId,
    groupFormKey,
    setGroupFormKey,
}: ReadOnlyReviewProps & {
    workEntity?: {
        title: string;
        description: string | null;
        field: Field_WorkEntityField_Fragment;
    };
    groupFormSections: Record<string, React.ReactNode>;
    briefId: string;
    groupFormKey: number;
    setGroupFormKey: React.Dispatch<React.SetStateAction<number>>;
}) {
    const { expressCheckoutGroup } = useBriefExperiments();
    const { accessToken, shopperId } = useIdentityContext();
    const { client } = useStatsigClient();
    const locale = getCurrentCulture();
    const { isInCart } = useCart(locale, { accessToken, shopperId });
    const isEditMode = useIsEditMode();
    const screenClass = useScreenClass();
    const {
        formState: { errors },
    } = useFormContext();
    const hasErrors = Object.keys(errors).length > 0;
    const { config } = useBriefContext();
    const [briefIsInCart, setBriefIsInCart] = useState<boolean>(false);

    const handlePrevious = useHandlePrevious(groups.length);
    const handleBuyNow = useHandleBuyNow();

    useEffect(() => {
        async function getCartData() {
            const inCart = await isInCart(briefId);
            setBriefIsInCart(inCart);
        }
        getCartData();
    }, [briefId, isInCart]);

    const isNotInCart = !isEditMode && !briefIsInCart;
    const showExpressCheckout = expressCheckoutGroup === 'test' && isNotInCart;

    let buyNowAction;

    if (showExpressCheckout) {
        buyNowAction = () => {
            fireBuyNowImpression(client);
            return (
                <Button
                    width={screenClass === 'xs' ? 'full-width' : 'standard'}
                    skin="primary"
                    ml={3}
                    onClick={handleBuyNow}
                    disabled={hasErrors}
                >
                    {__('Buy now')}
                </Button>
            );
        };
    } else if (expressCheckoutGroup === 'control' && isNotInCart) {
        buyNowAction = fireBuyNowImpression(client);
    } else {
        buyNowAction = undefined;
    }

    return (
        <StepLayout
            key={'review'}
            BuyNowAction={buyNowAction}
            NextAction={() => (
                <SubmitMultiStepBriefButton
                    skin={showExpressCheckout ? 'secondary' : 'primary'}
                    width={showExpressCheckout && screenClass === 'xs' ? 'full-width' : 'standard'}
                    isEditMode={isEditMode}
                />
            )}
            PreviousAction={() =>
                isEditMode ? (
                    <span />
                ) : (
                    <Button skin="tertiary" onClick={handlePrevious} iconPosition="left">
                        <Icon iconType="arrowLeft" />
                        {__('Back')}
                    </Button>
                )
            }
            Information={() => <Information product={product} />}
            showProgressBar={false}
            maxWidth={'894px'}
        >
            <Box mb={10} mx={5} mt={5}>
                <H1 fontSkin="title-headline">{__('Give your brief a once-over')}</H1>
                <Typography mb="6">
                    {__('Take a moment to review your design project details')}
                </Typography>
                {product && <StepProductCard workEntity={workEntity} product={product} />}
                {groups.map((group) => (
                    <ReviewCard
                        key={`${group.id}-card`}
                        group={group}
                        briefId={briefId}
                        groupFormSections={groupFormSections}
                        isEditMode={isEditMode}
                        groupFormKey={groupFormKey}
                        setGroupFormKey={setGroupFormKey}
                    />
                ))}
                {config?.showDta && <TermsAndConditions />}
            </Box>
        </StepLayout>
    );
}

interface ReviewCardProps {
    group: FieldGroupData;
    briefId: string;
    groupFormSections: Record<string, React.ReactNode>;
    isEditMode: boolean;
    groupFormKey: number;
    setGroupFormKey: React.Dispatch<React.SetStateAction<number>>;
}

function ReviewCard({
    group,
    briefId,
    groupFormSections,
    isEditMode,
    setGroupFormKey,
}: ReviewCardProps) {
    const {
        formState: { errors },
    } = useFormContext();

    const groupErrors = group.fields.flatMap((f) => {
        return errors[f.id] != null ? [errors[f.id]] : [];
    });
    const groupHasErrors = groupErrors.length > 0;

    return (
        <Card my="6" p="5" bordered style={{ position: 'relative' }}>
            <GridContainer>
                <Row>
                    <ReviewCardContent group={group} groupHasErrors={groupHasErrors} />
                    <EditFormGroupModal
                        briefId={briefId}
                        groupHasErrors={groupHasErrors}
                        showEditButton={group.id !== 'reviewingYourDesign' || !isEditMode}
                        setGroupFormKey={setGroupFormKey}
                    >
                        {groupFormSections[group.id]}
                    </EditFormGroupModal>
                </Row>
            </GridContainer>
        </Card>
    );
}

function ReviewCardContent({
    group,
    groupHasErrors,
}: {
    group: FieldGroupData;
    groupHasErrors: boolean;
}) {
    return (
        <Column pl={0} span={11} spanXs={11}>
            <Typography fontSkin="title-subsection">
                {reviewGroupTitleWithOverrides(group)}
            </Typography>
            {groupHasErrors && (
                <Typography mt={5} textColor={'error'} fontSkin="body-standard">
                    {__('This section has missing required fields')}
                </Typography>
            )}
            {group.fields.map((field) => {
                return (
                    <FieldReview
                        key={`field-review-${field.id}`}
                        skin="standard"
                        field={field}
                        colWidthOverride={6}
                        showTitle={group.fields.length > 1}
                    />
                );
            })}
        </Column>
    );
}

export function ReviewCardReadOnly({ group }: { group: FieldGroupData }) {
    return (
        <Card my="6" p="6" bordered style={{ position: 'relative' }}>
            <ReviewCardContent group={group} groupHasErrors={false} />
        </Card>
    );
}
