import React, { ReactNode, createContext, useContext, useState } from 'react';
import { AlertBox, AlertBoxDismissButton } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

interface AlertContextType {
    showError: (message: string) => void;
    showSuccess: (message: string) => void;
    messages?: JSX.Element[];
}

export const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlertContext = () => {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

interface AlertProviderProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

const FixedBannerLayout = styled.div`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1000;
`;

interface Message {
    id: string;
    text: string;
    type: 'error' | 'positive';
}

const MESSAGE_TIMEOUT = 5000;

export const AlertProvider: React.FC<AlertProviderProps> = ({ children, style }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const addMessage = (message: Message) => setMessages([...messages, message]);
    const removeMessage = (id: string) => setMessages(messages.filter((m) => m.id !== id));
    const showSuccess = (text: string) => {
        const id = Date.now().toString();
        addMessage({ id, text, type: 'positive' });
        setTimeout(() => removeMessage(id), MESSAGE_TIMEOUT);
    };
    const showError = (text: string) => {
        const id = Date.now().toString();
        addMessage({ id, text, type: 'error' });
        setTimeout(() => removeMessage(id), MESSAGE_TIMEOUT);
    };

    const isVisible = messages.length > 0;

    const messageComponents = messages.map((message) => (
        <AlertBox key={message.id} skin={message.type}>
            {message.text}
            <AlertBoxDismissButton
                onClick={() => removeMessage(message.id)}
                accessibleText={__('Dismiss alert')}
            />
        </AlertBox>
    ));

    return (
        <AlertContext.Provider value={{ showError, showSuccess, messages: messageComponents }}>
            {children}
            {isVisible && <FixedBannerLayout style={style}>{messageComponents}</FixedBannerLayout>}
        </AlertContext.Provider>
    );
};
