/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import VistaprintAuth from '@vp/auth';
import {
    AUTH_CIMPRESS_INTERNAL_CLAIM_KEY,
    AUTH_UNIFIED_IDENTITIES_CLAIM,
    IDENTITY_UNIFICATION_ID_99DESIGNS,
} from '../../consts';

type Profile = {
    'https://claims.cimpress.io/unified_identities': string[][];
};

export type IdentitySession = {
    profile: Profile;
    isSignedIn: boolean;
    canonicalId: string;
    recentAnonymousIds: string[];
    accessToken: string;
};

export type IdentityContextType = {
    auth: WebAuth | undefined;
    isSignedIn: boolean;
    isInternalUser: (vpAuth: WebAuth | null) => boolean;
    shopperId: string;
    anonymousUserId: string;
    accessToken: string;
    ninetyNineUserId: string;
    SignIn: () => void;
    SignOut: () => void;
};

export const IdentityContext = createContext<IdentityContextType | undefined>(undefined);

export type AuthDevMode =
    | boolean
    | {
          clientID: string;
          redirectUriOrigin?: string;
          domain?: string;
      };

export type AuthOptions = {
    clientID?: string;
    connection?: string;
    redirectAfterSignOutCallback?: string;
};

type IdentityProviderProps = {
    children: React.ReactNode;
    developmentMode: AuthDevMode;
    locale: string;
    options?: AuthOptions;
};

interface UserUpdate {
    (identitySession: IdentitySession): void;
}

interface WebAuth {
    signIn(): void;
    signOut(): void;
    onUserIdentityUpdate(callback: UserUpdate): void;
    getToken(): string;
    getProfile: () => object;
}

export const IdentityProvider = ({
    children,
    developmentMode,
    locale,
    options,
}: IdentityProviderProps) => {
    const [auth, setAuthState] = useState<WebAuth>();
    const [ninetyNineUserId, setNinetyNineUserId] = useState<string>('');
    const [shopperId, setShopperId] = useState<string>('');
    const [anonymousUserId, setAnonymousUserId] = useState<string>('');
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>('');
    const SignIn = () => {
        if (!auth) return;
        auth.signIn();
    };

    const SignOut = () => {
        if (!auth) return;
        setIsSignedIn(false);
        auth.signOut();
    };

    const isInternalUser = () => {
        if (!auth) {
            return false;
        }

        const profile = auth.getProfile() as Record<string, boolean>;
        return !!profile?.[AUTH_CIMPRESS_INTERNAL_CLAIM_KEY];
    };

    useEffect(() => {
        const config = { developmentMode, culture: locale, options: options };
        VistaprintAuth.init(config, () => {
            const authLibrary = new VistaprintAuth.WebAuth();
            setAuthState(authLibrary);
        });
    }, [developmentMode, locale, options]);

    useEffect(() => {
        if (!auth) return;
        auth.onUserIdentityUpdate((newIdentity: IdentitySession) => {
            if (!newIdentity) {
                return;
            }
            setShopperId(newIdentity.canonicalId);
            setAccessToken(newIdentity.accessToken);
            setNinetyNineUserId(getNinetyNineUserId(newIdentity));
            setAnonymousUserId(getAnonymousUserId(newIdentity));
            setIsSignedIn(newIdentity.isSignedIn);
        });
    }, [auth]);

    useEffect(() => {
        Bugsnag.addMetadata('viewer', {
            ninetyNineUserId,
            shopperId,
            anonymousUserId,
        });
    }, [ninetyNineUserId, shopperId, anonymousUserId]);

    return (
        <IdentityContext.Provider
            value={{
                auth,
                isSignedIn,
                isInternalUser,
                shopperId,
                anonymousUserId,
                accessToken,
                ninetyNineUserId,
                SignIn,
                SignOut,
            }}
        >
            {children}
        </IdentityContext.Provider>
    );
};

export function MockIdentityProvider({
    children,
    value = {},
}: {
    children: React.ReactNode;
    value?: Partial<IdentityContextType>;
}) {
    return (
        <IdentityContext.Provider
            value={{
                auth: undefined,
                isSignedIn: false,
                isInternalUser: () => false,
                shopperId: '',
                anonymousUserId: '',
                accessToken: '',
                ninetyNineUserId: '',
                SignIn: () => {},
                SignOut: () => {},
                ...value,
            }}
        >
            {children}
        </IdentityContext.Provider>
    );
}

export function getNinetyNineUserId(identity: IdentitySession) {
    let userId = '';

    if (!identity.isSignedIn || !identity.profile[AUTH_UNIFIED_IDENTITIES_CLAIM]) {
        return userId;
    }

    identity.profile[AUTH_UNIFIED_IDENTITIES_CLAIM].forEach((identity) => {
        if (identity[0] === IDENTITY_UNIFICATION_ID_99DESIGNS) {
            userId = identity[1] ?? '';
            return;
        }
    });
    return userId;
}

export function getAnonymousUserId(identity: IdentitySession) {
    if (!identity.isSignedIn) {
        return identity.canonicalId;
    }
    return identity.recentAnonymousIds?.[0];
}

export function useIdentityContext() {
    const context = useContext(IdentityContext);
    if (context === undefined) {
        throw new Error('useIdentityContext must be used within an IdentityProvider');
    }
    return context;
}
