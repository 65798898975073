/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type SubmitRequestMutationVariables = Types.Exact<{
    requestId: Types.Scalars['ID'];
}>;

export type SubmitRequestMutation = {
    __typename: 'Mutation';
    submitRequest: {
        __typename: 'Request';
        publicId: string;
        facts: Types.Maybe<{ __typename: 'RequestFacts'; currentUrl: Types.Maybe<string> }>;
    };
};

export const SubmitRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SubmitRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submitRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'requestId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'requestId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'facts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currentUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type SubmitRequestMutationFn = Apollo.MutationFunction<
    SubmitRequestMutation,
    SubmitRequestMutationVariables
>;

/**
 * __useSubmitRequestMutation__
 *
 * To run a mutation, you first call `useSubmitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRequestMutation, { data, loading, error }] = useSubmitRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSubmitRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<SubmitRequestMutation, SubmitRequestMutationVariables>
) {
    return Apollo.useMutation<SubmitRequestMutation, SubmitRequestMutationVariables>(
        SubmitRequestDocument,
        baseOptions
    );
}
export type SubmitRequestMutationHookResult = ReturnType<typeof useSubmitRequestMutation>;
export type SubmitRequestMutationResult = Apollo.MutationResult<SubmitRequestMutation>;
export type SubmitRequestMutationOptions = Apollo.BaseMutationOptions<
    SubmitRequestMutation,
    SubmitRequestMutationVariables
>;
