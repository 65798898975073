import Bugsnag from '@bugsnag/js';
import { ClaimBriefMutation } from '../../../../../graph/ClaimBrief.generated';
import { DesignServiceRequestType } from '../../../../Submit/consts';

const DEFAULT_PRODUCT_OPTIONS_DECISION = 'CUSTOMER';

export default function decisionFromBrief(
    briefData: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): string {
    const field = briefData.find((field) => field.id === 'productOptionsDecision');

    // Consider the field not existing as valid (older schema?) and default to CUSTOMER, showing the product options.

    if (!field) {
        return DEFAULT_PRODUCT_OPTIONS_DECISION;
    }

    // If the field exists, it should be retrievable as one of the single choice select field types.

    if (
        field.__typename === 'GridChoiceField' ||
        field.__typename === 'RadioChoiceField' ||
        field.__typename === 'SelectChoiceField'
    ) {
        return (field.choiceValue as DesignServiceRequestType) || DEFAULT_PRODUCT_OPTIONS_DECISION;
    }

    // If the value of requestType exists but is not retrievable, there is an error. As throwing an error will break the flow but be equally visible, we will log the error to Bugsnag.

    Bugsnag.notify(new Error(`Unknown field type for requestType: ${field.__typename}`));

    return DEFAULT_PRODUCT_OPTIONS_DECISION;
}
