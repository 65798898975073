import { Box, Progress, responsive } from '@vp/swan';
import { useStepContext } from '../context/StepContext';

const ResponsiveBox = responsive(Box);

export const ProgressBar = () => {
    const { currentStep, totalSteps } = useStepContext();
    return (
        <ResponsiveBox
            padding={5}
            xs={{
                style: {
                    width: '100%',
                },
            }}
            sm={{
                style: {
                    width: '300px',
                    alignSelf: 'flex-start',
                },
            }}
        >
            <Progress
                value={(currentStep / (totalSteps - 1)) * 100}
                aria-label={`Progress: Step ${currentStep} of ${totalSteps - 1}`}
                style={{
                    width: '100%',
                }}
            />
        </ResponsiveBox>
    );
};
