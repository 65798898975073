import React, { ReactNode, useMemo } from 'react';
import { BoundedContent, Box, Link, Typography } from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { PageLayout } from '@99designs/design-services-layouts';
import { __, getCurrentCulture } from '@99designs/i18n';
import { PageTracking } from '@99designs/tracking';
import Ghost from './Ghost.png';

export function NotFoundErrorPage() {
    return (
        <ErrorWrapper pageName="Not Found">
            <GenericError title={__("Boo. That page didn't turn up - but we're here to help.")} />
        </ErrorWrapper>
    );
}

export function PermissionErrorPage() {
    return (
        <ErrorWrapper pageName="Permission Denied">
            <GenericError title={__('You do not have permission to access this page.')} />
        </ErrorWrapper>
    );
}

export function ErrorPage({
    title,
    error,
    metadata,
}: {
    title?: string;
    error?: Error;
    metadata?: Record<string, unknown>;
}) {
    if (error) {
        notifyBugtracker(error, metadata);
    }

    return (
        <ErrorWrapper pageName="Error">
            <GenericError title={title || __('Boo. Something went wrong.')} />
        </ErrorWrapper>
    );
}

function GenericError({ title }: { title: string }) {
    return (
        <BoundedContent>
            <Box marginTop={10} marginBottom={'7'} textAlign="center">
                <img alt="ghost" src={Ghost} width={128} height={128} />
                <Box marginTop={8}>
                    <Typography fontSize="x3large" fontWeight="bold">
                        {title}
                    </Typography>
                </Box>
                <Box marginTop={8} marginBottom={10}>
                    <Link href="/">{__('Get back to our homepage')}</Link>
                </Box>
            </Box>
        </BoundedContent>
    );
}

function ErrorWrapper({ pageName, children }: { pageName: string; children: ReactNode }) {
    const locale = useMemo(() => getCurrentCulture(), []);

    const tracking: PageTracking = {
        pageName: `Briefs:${pageName}`,
        pageSection: 'My Account',
        pageStage: 'My Account',
        pageDept: 'Design Services',
    };

    return (
        <PageLayout locale={locale} breadcrumbs={[]} pageTracking={tracking}>
            {children}
        </PageLayout>
    );
}

export function notifyBugtracker(error: Error, metadata?: Record<string, unknown>) {
    if (metadata) {
        bugtracker.notify(error, (event) => {
            Object.entries(metadata).forEach(([key, value]) => {
                event.addMetadata('debug info', key, value);
            });
        });
    } else {
        bugtracker.notify(error);
    }
}
