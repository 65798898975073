import i18n, { FallbackLng, Resource } from 'i18next';
import { absoluteUrlTranslations } from '@99designs/i18n';
import dadk from '../assets/translations/da-DK.json';
import dede from '../assets/translations/de-DE.json';
import enau from '../assets/translations/en-AU.json';
import enca from '../assets/translations/en-CA.json';
import engb from '../assets/translations/en-GB.json';
import enie from '../assets/translations/en-IE.json';
import enin from '../assets/translations/en-IN.json';
import enus from '../assets/translations/en-US.json';
import eses from '../assets/translations/es-ES.json';
import esus from '../assets/translations/es-US.json';
import fifi from '../assets/translations/fi-FI.json';
import frca from '../assets/translations/fr-CA.json';
import frfr from '../assets/translations/fr-FR.json';
import itit from '../assets/translations/it-IT.json';
import nbno from '../assets/translations/nb-NO.json';
import nlnl from '../assets/translations/nl-NL.json';
import ptpt from '../assets/translations/pt-PT.json';
import svse from '../assets/translations/sv-SE.json';
import dadkURLs from '../assets/urls/da-DK.json';
import deatURLs from '../assets/urls/de-AT.json';
import dechURLs from '../assets/urls/de-CH.json';
import dedeURLs from '../assets/urls/de-DE.json';
import enauURLs from '../assets/urls/en-AU.json';
import encaURLs from '../assets/urls/en-CA.json';
import engbURLs from '../assets/urls/en-GB.json';
import enieURLs from '../assets/urls/en-IE.json';
import eninURLs from '../assets/urls/en-IN.json';
import ennzURLs from '../assets/urls/en-NZ.json';
import ensgURLs from '../assets/urls/en-SG.json';
import enusURLs from '../assets/urls/en-US.json';
import esesURLs from '../assets/urls/es-ES.json';
import esusURLs from '../assets/urls/es-US.json';
import fifiURLs from '../assets/urls/fi-FI.json';
import frbeURLs from '../assets/urls/fr-BE.json';
import frcaURLs from '../assets/urls/fr-CA.json';
import frchURLs from '../assets/urls/fr-CH.json';
import frfrURLs from '../assets/urls/fr-FR.json';
import itchURLs from '../assets/urls/it-CH.json';
import ititURLs from '../assets/urls/it-IT.json';
import nbnoURLs from '../assets/urls/nb-NO.json';
import nlbeURLs from '../assets/urls/nl-BE.json';
import nlnlURLs from '../assets/urls/nl-NL.json';
import ptptURLs from '../assets/urls/pt-PT.json';
import svseURLs from '../assets/urls/sv-SE.json';

const resources: Resource = {
    'da-dk': {
        translation: {
            ...absoluteUrlTranslations.dadkURLs,
            ...dadk,
            ...dadkURLs,
        },
    },
    'de-at': {
        translation: {
            ...absoluteUrlTranslations.deatURLs,
            ...dede,
            ...deatURLs,
        },
    },
    'de-ch': {
        translation: {
            ...absoluteUrlTranslations.dechURLs,
            ...dede,
            ...dechURLs,
        },
    },
    'de-de': {
        translation: {
            ...absoluteUrlTranslations.dedeURLs,
            ...dede,
            ...dedeURLs,
        },
    },
    'en-au': {
        translation: {
            ...absoluteUrlTranslations.enauURLs,
            ...enau,
            ...enauURLs,
        },
    },
    'en-ca': {
        translation: {
            ...absoluteUrlTranslations.encaURLs,
            ...enca,
            ...encaURLs,
        },
    },
    'en-gb': {
        translation: {
            ...absoluteUrlTranslations.engbURLs,
            ...engb,
            ...engbURLs,
        },
    },
    'en-ie': {
        translation: {
            ...absoluteUrlTranslations.enieURLs,
            ...enie,
            ...enieURLs,
        },
    },
    'en-in': {
        translation: {
            ...absoluteUrlTranslations.eninURLs,
            ...enin,
            ...eninURLs,
        },
    },
    'en-nz': {
        translation: {
            ...absoluteUrlTranslations.ennzURLs,
            ...enau,
            ...ennzURLs,
        },
    },
    'en-sg': {
        translation: {
            ...absoluteUrlTranslations.ensgURLs,
            ...engb,
            ...ensgURLs,
        },
    },
    'en-us': {
        translation: {
            ...absoluteUrlTranslations.enusURLs,
            ...enus,
            ...enusURLs,
        },
    },
    'es-es': {
        translation: {
            ...absoluteUrlTranslations.esesURLs,
            ...eses,
            ...esesURLs,
        },
    },
    'es-us': {
        translation: {
            ...absoluteUrlTranslations.esusURLs,
            ...esus,
            ...esusURLs,
        },
    },
    'fi-fi': {
        translation: {
            ...absoluteUrlTranslations.fifiURLs,
            ...fifi,
            ...fifiURLs,
        },
    },
    'fr-be': {
        translation: {
            ...absoluteUrlTranslations.frbeURLs,
            ...frfr,
            ...frbeURLs,
        },
    },
    'fr-ca': {
        translation: {
            ...absoluteUrlTranslations.frcaURLs,
            ...frca,
            ...frcaURLs,
        },
    },
    'fr-ch': {
        translation: {
            ...absoluteUrlTranslations.frchURLs,
            ...frfr,
            ...frchURLs,
        },
    },
    'fr-fr': {
        translation: {
            ...absoluteUrlTranslations.frfrURLs,
            ...frfr,
            ...frfrURLs,
        },
    },
    'it-ch': {
        translation: {
            ...absoluteUrlTranslations.itchURLs,
            ...itit,
            ...itchURLs,
        },
    },
    'it-it': {
        translation: {
            ...absoluteUrlTranslations.ititURLs,
            ...itit,
            ...ititURLs,
        },
    },
    'nb-no': {
        translation: {
            ...absoluteUrlTranslations.nbnoURLs,
            ...nbno,
            ...nbnoURLs,
        },
    },
    'nl-be': {
        translation: {
            ...absoluteUrlTranslations.nlbeURLs,
            ...nlnl,
            ...nlbeURLs,
        },
    },
    'nl-nl': {
        translation: {
            ...absoluteUrlTranslations.nlnlURLs,
            ...nlnl,
            ...nlnlURLs,
        },
    },
    'pt-pt': {
        translation: {
            ...absoluteUrlTranslations.ptptURLs,
            ...ptpt,
            ...ptptURLs,
        },
    },
    'sv-se': {
        translation: {
            ...absoluteUrlTranslations.svseURLs,
            ...svse,
            ...svseURLs,
        },
    },
};

const fallbackLng: FallbackLng = {
    'en-ca': ['en-au', 'en-gb', 'en-us'],
    'en-nz': ['en-au', 'en-gb', 'en-us'],
    'en-au': ['en-gb', 'en-us'],
    'en-ie': ['en-gb', 'en-us'],
    'en-in': ['en-gb', 'en-us'],
    'en-sg': ['en-gb', 'en-us'],
    'en-gb': ['en-us'],
    'fr-be': ['fr-fr', 'en-us'],
    'fr-ca': ['fr-fr', 'en-us'],
    'fr-ch': ['fr-fr', 'en-us'],
    'es-us': ['es-es', 'en-us'],
    'nl-be': ['nl-nl', 'en-us'],
    'de-at': ['de-de', 'en-us'],
    'de-ch': ['de-de', 'en-us'],
    'it-ch': ['it-it', 'en-us'],
    default: ['en-us'],
};

void i18n.init({
    debug: false,
    resources,
    fallbackLng,
    lowerCaseLng: true,
    interpolation: {
        escapeValue: false,
    },
    nsSeparator: '*****',
    returnObjects: true,
});

export default i18n;
