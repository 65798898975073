import React, { PropsWithChildren } from 'react';
import { Box, FlexBox, responsive } from '@vp/swan';
import { BottomBar } from './BottomBar';
import { ProgressBar } from './ProgressBar';

const ResponsiveFlexBox = responsive(FlexBox);
const ResponsiveBox = responsive(Box);

export const StepLayout = ({
    children,
    NextAction,
    BuyNowAction,
    PreviousAction,
    showProgressBar = true,
    maxWidth = '696px',
    Information,
}: {
    children: React.ReactNode;
    NextAction?: React.ComponentType;
    BuyNowAction?: React.ComponentType;
    PreviousAction?: React.ComponentType;
    showProgressBar?: boolean;
    maxWidth?: string;
    Information?: React.ComponentType;
}) => {
    return (
        <>
            <ShellInner>
                <ResponsiveBox
                    style={{ maxWidth: maxWidth, margin: 'auto' }}
                    xs={{ style: { width: '100%' } }}
                >
                    {showProgressBar && <ProgressBar />}
                    {children}
                </ResponsiveBox>
            </ShellInner>
            <BottomBar
                NextAction={NextAction}
                BuyNowAction={BuyNowAction}
                PreviousAction={PreviousAction}
                Information={Information}
            />
        </>
    );
};

export function ShellInner(props: PropsWithChildren) {
    return (
        <ResponsiveFlexBox
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            paddingBottom={11}
            style={{
                flex: 1,
                height: '100%',
                minWidth: 0,
                minHeight: 0,
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
            sm={{
                paddingTop: 9,
            }}
        >
            {props.children}
        </ResponsiveFlexBox>
    );
}
